export const languageOptionsDE = {
  downloadJPEG: "Diagramm als JPEG speichern",
  downloadPDF: "Diagramm als PDF speichern",
  downloadPNG: "Diagramm als PNG speichern",
  downloadSVG: "Diagramm als SVG speichern",
  months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "July", "August", "September", "Oktober", "November", "Dezember"],
  shortMonths: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  weekdays: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  decimalPoint: ",",
  printChart: "Diagramm drucken",
  rangeSelectorFrom: "Von",
  rangeSelectorTo: "Bis",
};
