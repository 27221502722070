import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from "@angular/forms";

@Directive({
  selector: "[appMin]",
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }],
})
export class MinValidatorDirective implements Validator {

  @Input() min: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return Validators.min(this.min)(control);
  }

}
