import { Injectable } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { NetworkError } from "./network-error";

@Injectable()
export class NotificationService {
  constructor(private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService) {
  }

  public showTranslatedSuccessMessage(message: string) {
    this.bfcNotificationService.showNotification(
      {
        type: BfcNotificationType.SUCCESS,
        message: message,
      },
    );
  }

  public showSuccessMessage(key: string, translationOptions?: any) {
    const message = translationOptions ?
      this.bfcTranslationService.translate(key, translationOptions) :
      this.bfcTranslationService.translate(key);
    this.showTranslatedSuccessMessage(message);
  }

  public showTranslatedWarningMessage(message: string) {
    this.bfcNotificationService.showNotification(
      {
        type: BfcNotificationType.WARNING,
        message: message,
      },
    );
  }

  public showErrorMessage(key: string, translationOptions?: any) {
    const message = translationOptions ?
      this.bfcTranslationService.translate(key, translationOptions) :
      this.bfcTranslationService.translate(key);
    this.bfcNotificationService.showNotification(
      {
        type: BfcNotificationType.ERROR,
        message: message,
      },
    );
  }

  public showErrorWithErrorCode(error: NetworkError, fallbackErrorMsgKey: string = "ERROR.COMMON_ERROR_MESSAGE") {
    const message = (error && error.error_code ?
      this.bfcTranslationService.translate("ERROR.ERROR_CODE." + error.error_code) :
      this.bfcTranslationService.translate(fallbackErrorMsgKey)
    );
    this.bfcNotificationService.showNotification(
      {
        type: BfcNotificationType.ERROR,
        message: message,
      },
    );
  }
}
