import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { P_ENV } from "./environments/p";
import { Q_ENV } from "./environments/q";
import { T_ENV } from "./environments/t";

export const configuration: IBfcConfiguration[] = [{
  theme: "bkw",
  general: GENERAL_ENV,
  local: LOCAL_ENV,
  t: T_ENV,
  q: Q_ENV,
  p: P_ENV,
}];

export interface TangentoConfiguration {
  myApiUrl?: string;
  googleTagManagerContainerId?: string;
  userRole?: string;
  b2bNavigationUrl?: string;
  b2bDashboardUrl?: string;
  handsonTableLicenseKey?: string;
}
