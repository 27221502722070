import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable, of } from "rxjs";
import { map, share, tap } from "rxjs/operators";
import { NavigationTree } from "./navigation-tree.interface";
import { Level } from "./level.interface";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable()
export class NavigationTreeService {

  private navigationTree: Observable<string>;

  private readonly userRole = this.bfcConfigurationService.configuration.userRole;

  constructor(private httpClient: HttpClient,
    private bfcTranslationService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcAuthenticationService: BfcAuthenticationService) {

  }

  getNavigationTree(): Observable<string> {
    if (!this.navigationTree && this.bfcAuthenticationService.hasRealmRole(this.userRole)) {
      this.navigationTree = this.httpClient
        .get(this.bfcConfigurationService.configuration.b2bNavigationUrl + "?language=" + this.bfcTranslationService.language)
        .pipe(
          map((navigationList: Level[]) => {
            // what we get: labelKey, subNavigationItems, url
            if (navigationList && navigationList.length > 0) {
              // update service Codes for cloud events:
              navigationList.forEach((entry: Level) => {
                if (!entry.roles || entry.roles.length === 0){
                  entry.roles = [];
                }
                entry.roles.push(this.userRole);
              });
            }

            const tree: NavigationTree = {
              serviceName: this.bfcTranslationService.translate("SERVICE_NAME"),
              parentUrl: this.bfcConfigurationService.configuration.b2bDashboardUrl,
              subNavigationItems: navigationList,
            };
            return tree;
          }),
          tap(navigationTree => {
            const extendUrl = (item) => {
              item.url = {
                base: window.location.origin,
                path: item.url,
              };
              if (item.subNavigationItems && item.subNavigationItems.length > 0){
                item.subNavigationItems.forEach(i => extendUrl(i));
              }
            };
            navigationTree.subNavigationItems.forEach(item => extendUrl(item));
          }),
          map(navigationTree => JSON.stringify(navigationTree)),
          share(),
        );
    } else {
      this.navigationTree = of();
    }
    return this.navigationTree;
  }
}
