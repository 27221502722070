import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BfcTranslationService } from "@bfl/components/translation";
import * as moment from "moment";
import { from, Subject } from "rxjs";
import { flatMap, map, takeUntil } from "rxjs/operators";
import { AdhocQueueJob } from "../../model/rest/adhoc-queue-job";
import { adhocJobUnfinishedStates } from "../models/adhoc-job-states";
import { AdhocOptimizationService } from "../services/ad-hoc-optimization.service";

@Component({
  selector: "app-optimization-status-dialog",
  templateUrl: "optimization-status-dialog.component.html",
  styleUrls: ["optimization-status-dialog.component.scss"],
})
export class OptimizationStatusDialogComponent implements OnInit {
  private unsubscribe: Subject<void> = new Subject<void>();

  private readonly adhocJobUnfinishedStates = adhocJobUnfinishedStates;

  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public optimizationDataStatus,
    private bfcTranslationService: BfcTranslationService,
    private adhocOptimizationService: AdhocOptimizationService) {
  }

  ngOnInit() {
    // Sort jobs by their position
    this.optimizationDataStatus.optimizationDataStatus =
      this.optimizationDataStatus.optimizationDataStatus.sort((a, b) => b.position - a.position);

    this.adhocOptimizationService.pollAllQueues(this.optimizationDataStatus.powerplants).pipe(
      flatMap((jobs: AdhocQueueJob[][]) => from(jobs)),
      map((jobs: AdhocQueueJob[]) => jobs
        .filter(job => this.adhocJobUnfinishedStates.indexOf(job.optimizationState) > -1)
        .sort((a, b) => b.position - a.position)),
      takeUntil(this.unsubscribe),
    ).subscribe(
      (filteredJobs: AdhocQueueJob[]) => {
        this.optimizationDataStatus.optimizationDataStatus = filteredJobs;
      },
      (error: unknown) => {
        // eslint-disable-next-line no-console
        console.log("error polling all queues in dialog", error);
      },
    );
  }

  cancel() {
    // End Polling subscription
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.dialogRef.close();
  }

  cancelOptimization() {
    // End Polling subscription
    this.unsubscribe.next();
    this.unsubscribe.complete();

    const onlyCheckedJobs = this.optimizationDataStatus.optimizationDataStatus.filter((job: AdhocQueueJob) => {
      return job.checked;
    });
    this.dialogRef.close(onlyCheckedJobs);
  }

  humanizeDuration(duration: number): string {
    const momentDuration = moment.duration(duration, "milliseconds");
    const hours = Math.floor(momentDuration.asHours());
    const mins = Math.floor(momentDuration.asMinutes()) - hours * 60;

    if (hours > 0) {
      return hours + "h " + mins + "min";
    } else {
      return mins + "min";
    }
  }

  /**
     * Adhoc Optimization is only available from 0800-1700
     * @returns {boolean}
     */
  isAdhocEnabled(): boolean {
    const now = new Date();
    return now.getHours() >= 8 && now.getHours() < 17;
  }
}
