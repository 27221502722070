export const EN_TRANSLATIONS = {
  "SERVICE_NAME": "Energy Asset",
  "GLOBAL": {
    "LOADING_OVERLAY": {
      "PLEASE_WAIT": "Please wait",
    },
    "FOOTER": {
      "CONTACT_TITLE": "Contact",
      "CONTACT_FORM.LABEL": "Contact form",
      "CONTACT_CUSTOMER_CARE": "Services",
      "CONTACT_SERVICE_FAILURE": "Disruptions",
      "CONTACT_HEAD_OFFICE": "Headquarter",
      "CUSTOMER_SERVICE_TITLE": "Services",
      "MY_BKW": {
        "LABEL": "My BKW",
        "URL": "https://account.bkw.ch",
      },
      "FAQ": {
        "LABEL": "FAQ",
        "URL": "https://www.bkw.ch/privatkunden/kundenservice/haeufige-fragen/",
      },
      "BILLS": {
        "LABEL": "Bills",
        "URL": "http://www.bkw.ch/privatkunden/kundenservice/die-rechnung/",
      },
      "CHANGE_OF_ADDRESS": {
        "LABEL": "Address Change",
        "URL": "https://account.bkw.ch/account",
      },
      "NEWSLETTER": {
        "LABEL": "Newsletter",
        "URL": "https://www.bkw.ch/seite/bkw-newsletter/",
      },
      "BLACKOUT_HINT": {
        "LABEL": "Power outages / power disturbances",
        "URL": "https://services.bkw.ch/stromausfall",
      },
      "ABOUT_TITLE": "About",
      "ABOUT_US": {
        "LABEL": "Company",
        "URL": "https://www.bkw.ch/en/about-us/company/about-us/",
      },
      "JOBS": {
        "LABEL": "Jobs",
        "URL": "https://www.bkw.ch/ueber-bkw/jobs-karriere/offene-stellen/",
      },
      "PRESS_RELEASES": {
        "LABEL": "Press releases",
        "URL": "https://www.bkw.ch/en/about-us/media/press-releases/",
      },
      "INVESTOR": {
        "LABEL": "Investor relations",
        "URL": "https://www.bkw.ch/en/about-us/investor-relations/",
      },
      "PARTNER_BKW": {
        "LABEL": "BKW partners",
        "URL": "https://www.bkw.ch/ueber-bkw/unser-engagement/branchenpartnerschaften/",
      },
      "SOCIAL_NETWORKS_TITLE": "Social Media",
      "LEGAL_TITLE": "Legal",
      "DISCLAIMER": {
        "LABEL": "Disclaimer",
        "URL": "https://www.bkw.ch/en/special-navigations/rechtliches/disclaimer/",
      },
      "DATA_STATEMENT": {
        "LABEL": "Privacy Policy",
        "URL": "https://www.bkw.ch/en/special-navigations/rechtliches/privacy-policy/",
      },
      "LEGAL_INFORMATION": {
        "LABEL": "Impressum",
        "URL": "https://www.bkw.ch/en/special-navigations/rechtliches/impressum/",
      },
      "TERM_OF_USE": {
        "LABEL": "Terms of use",
        "URL": "https://www.bkw.ch/fileadmin/oneportal/mybkw/documents/AGB_Nutzungsbedingungen_Online_Kundencenter_A4_D_BKW_Web.pdf",
      },
      "GENERAL_BUSINESS_TERMS": {
        "LABEL": "General Terms and Conditions",
        "URL": "https://www.bkw.ch/en/about-us/media/download-center/#c26591",
      },
    },
  },
  "UNAUTHORIZED_COMPONENT": {
    "HEADING": "No authorization",
    "MESSAGE": "No authorization for the selected power plant",
    "MESSAGE_1": "In case of questions or problem please contact:",
    "CONTACT_NAME": "Dr. Anton Lüthi",
    "CONTACT_PHONE": "Direct",
    "CONTACT_PHONE_LINK_NAME": "+41 58 477 50 75",
    "CONTACT_PHONE_LINK": "+41584775075",
    "CONTACT_MAIL": "anton.luethi2@bkw.ch",
  },
  "START_SCREEN": {
    "MARKET": "Market area: {{marketId}}",
    "TITLE": "Complete Overview",
    "POWERPLANTS": "Power plants - {{marketPlace}}",
  },
  "CONTACT_COMPONENT": {
    "HEADING": "Contact",
    "MESSAGE": "In case of questions or problem please contact:",
    "CONTACT_NAME": "Dr. Anton Lüthi",
    "CONTACT_PHONE": "Direct",
    "CONTACT_PHONE_LINK_NAME": "+41 58 477 50 75",
    "CONTACT_PHONE_LINK": "+41584775075",
    "CONTACT_MAIL": "anton.luethi2@bkw.ch",
  },
  "MARGIN_PRICE": {
    "DATA_TABLE": {
      "POWERPLANT": "power plant unit",
      "CATEGORY": "category",
      "MEDIAN": "median",
      "MEAN": "mean",
      "TITLE": "Margin Prices",
    },
  },
  "PORTFOLIO": {
    "GRAPH_TITLE": "Position {{index}} {{date}}",
    "GRAPH": {
      "DELTA_POS1_POS2": "Delta Pos 1 - Pos 2",
    },
  },
  "POWERPLANT_OVERVIEW": {
    "OVERVIEW": "Overview",
    "TSE_MODEL": "power plant topology",
  },
  "OPTIMIZATION": {
    "TITLE": "Scenario Optimization",
    "LOAD": "Load time series",
    "SAVE": "Save time series",
    "START_OPTIMIZATION": "Start optimization",
    "STOP_OPTIMIZATION": "Cancel optimization",
    "START": "Start",
    "STOP": "End",
    "RESET_TO_EOD": "Reset time series to EoD",
    "MARGINAL_CONDITIONS": "Boundary conditions (storage levels in %)",
    "OVERVIEW_AS_TABLE": "Time series table",
    "QUEUE": "Check optimization status",
    "AVAILABILTY_INFO": "Available only from 08:00 to 17:00",
    "FORM_ERRORS": {
      "MIN": "Value too small",
      "MAX": "Value too large",
      "REQUIRED": "Enter value",
      "NUMBER": "Enter valid value",
    },
    "STATUS_DIALOG": {
      "TITLE": "Current Calculations",
      "QUEUE_NUMBER": "Queue number",
      "POWERPLANT": "Power plant",
      "REMAINING_TIME": "Estimated optimization time",
      "STATE": "Status",
      "HINT_TEXT": "*Estimated calculation time after status Runningoptimization",
      "EMPTY_TEXT": "Currently no optimization scheduled",
    },
    "INVALID_VALUES_IN_TABLE": "Table contains invalid values",
    "ERROR_LOADING_TIME_SERIES_INVALID_CONFIGURATION": "Error while loading time series. The configuration is incorrect",
    "ERROR_LOADING_SEA_LEVELS": "Error while loading reservoir levels",
    "SUCCESSFULLY_SAVED_SEA_LEVELS_AND_TIME_SERIES": "Reservoir levels and time series data saved successfully",
    "SUCCESSFULLY_SAVED_SEA_LEVELS": "Reservoir levels saved successfully",
    "ERROR_SAVING_SEA_LEVELS_AND_TIME_SERIES": "Error while saving reservoir levels and time series data",
    "ERROR_SAVING_SEA_LEVELS": "Error while saving reservoir levels",
    "ERROR_PASTING_DATA_INVALID": "Error while inserting data. The data contains invalid values",
    "ERROR_PASTING_OUT_OF_BOUND": "Error while inserting data. The data contains more values than the table size",
    "ERROR_PASTING_TIMESTAMPS_MUST_BE_IDENTICAL": "Error while inserting data. Time stamps are not aligned",
    "TABLE_HEADER_TIMESTAMP": "Date / time",
    "SUCCESSFULLY_STARTED_OPTIMIZATION": "Optimization successfully started",
    "ERROR_STARTING_OPTIMIZATION": "Error starting optimization",
    "ERROR_OPTIMIZATION_ALREADY_RUNNING": "Power plant already in optimization queue",
    "OPTIMIZATION_CANCELLED": "Optimization cancelled",
    "ALL_OPTIMIZATIONS_CANCELLED": "Optimizations cancelled",
    "OPTIMIZATION_CANCEL_ERROR": "{{powerplant}} Job item (Nummer {{position}}) could not be cancelled",
    "OPTIMIZATION_CANCEL_SUCCESSFULL": "{{powerplant}} Job item (Nummer {{position}}) cancelled successfully",
    "ERROR_CANCELLING_OPTIMIZATION": "Optimization could not be cancelled",
    "ERROR_CANCELLING_OPTIMIZATIONS": "Optimizations could not be cancelled",
    "ERROR_LOADING_TIME_SERIES": "Error while loading time series",
    "ERROR_LOADING_QUEUE": "Error while loading optimization status",
    "OPTIMIZATION_FINISHED": "Optimization successfully finished",
    "EOD_RESET_SEA_LEVEL": "Reservoir levels rolled back to EoD",
    "EOD_RESET_TIMESERIE": "Time series was reset to EoD. Please use 'save time series' to save data.",
    "TABLE_TYPE_HEADING": "Time series table - {{kw}}",
    "SELECT_POWERPLANT": "Select power plant",
    "START_DATE": "Start date",
    "END_DATE": "End date",
    "SELECT_TIMESERIES_CATEGORY": "Time series category",
  },
  "TANGENTO_COMMON": {
    "DASHBOARD": {
      "PRODUCTION": "Production",
      "MARGINPRICES": "Margin Prices",
      "STORAGE": "Storages and Inflows",
      "OVERVIEW": "Power plant overview",
    },
    "TIME_RANGE_BUTTONS": {
      "HOUR": "Hour",
      "DAY": "Day",
      "WEEK": "Week",
      "MONTH": "Month",
      "YEAR": "Year",
    },
    "DATE": "Date",
    "AGGREGATION": "Aggregation",
    "OPTIONS": "Options",
    "DATA_STATUS": "Data set",
    "MEASUREMENT_POINT": "Date {{date}}",
    "ERROR_LOADING_DATA": "Error while loading data",
    "CHANGE_DATA": "Change data set",
    "SAVE": "Save",
    "CANCEL": "Cancel",
    "SELECT": "Select",
    "CONFIRM": "Confirm",
    "RESET_ALL_FILTERS": "Reset filter criteria",
    "AGGREGATIONS": {
      "HOUR": "hour",
      "DAY": "day",
      "WEEK": "week",
      "MONTH": "month",
      "QUARTER": "quarter",
      "YEAR": "year",
    },
    "CSV": "CSV",
    "FILTER": {
      "PHYSICALLY": "Physical",
      "FINANCIALLY": "Financial",
      "AMOUNTS_SUMS": "Volume / Sum",
      "PERFORMANCE_MEAN": "Power / Mean",
      "GROUPED": "Grouped",
      "PILED": "Stacked",
      "DISPLAY": "View",
      "POSITION_TITLE": "Position",
      "DATA_COLLECTION": "Data operations",
    },
    "POSITION": "Pos {{number}}",
    "DATE_FROM": "From",
    "DATE_TO": "To",
  },
  "GLOBAL_NAVIGATION": {
    "DASHBOARD": {
      "MARGINPRICES": "Margin Prices",
      "OVERVIEW": "Overview",
      "PRODUCTION": "Production",
      "STORAGE": "Storages and Inflows",
    },
    "PORTFOLIO": "Portfolio",
  },
  "DATA_STATUS_DIALOG_COMPONENT": {
    "TITLE": "Select data set",
    "CALCULATION": "Calculation",
    "ADHOC": "Adhoc",
  },
  "BKWFC_CHART_MODULE": {
    "TIME_RANGE_BUTTONS": {
      "1_MONTH": "1 month",
      "6_MONTHS": "6 months",
      "1_YEAR": "1 year",
      "1_WEEK": "1 week",
      "1_DAY": "1 day",
      "ALL": "all",
    },
    "HIGHSTOCK_DATETIME_FORMAT": {
      "millisecond": "%H:%M:%S.%L",
      "second": "%H:%M:%S",
      "minute": "%H:%M",
      "hour": "%H:%M",
      "day": "%e. %b",
      "week": "%e. %b",
      "month": "%b %y",
      "year": "%Y",
    },
    "EXPORT_AS_PNG": "PNG",
  },
  "TIME_SERIES_CATEGORY": {
    "STORAGE": "Storage",
    "INFLOWS": "Inflows",
    "CONSTRAINTS": "Constraints",
    "PRICE": "Prices",
    "OPERATIONALRESTRICTIONS": "Operational unit constraints",
  },
  "TIME_SERIES_TYPE": {
    "TIMESERIES": "Time series",
    "PRICE": "Price",
    "CONSTRAINT": "Constraint",
    "NOTDEFINED": "Standard",
    "POSITION": "Position",
  },
  "TOOLTIP": {
    "DATE_FORMAT": "%d.%m.%y %H:%M",
  },
  "ERROR": {
    "ERROR_CODE": {
      "NO_DATA_FOR_VERSION_DATE": "No data available for selected date",
      "TIMEOUT": "Network connection timeout",
    },
    "ERROR_FETCHING_GRAPH": "Error while loading graph",
    "ERROR_FETCHING_TABLE": "Error while loading table",
    "ERROR_FETCHING_CONFIGURATION": "Error while loading configuration",
    "ERROR_FETCHING_TSE_MODEL": "Error while loading power plant topology",
    "COMMON_ERROR_MESSAGE": "An error occurred",
    "SOME_TIME_SERIES_NOT_LOADED": "Time series '{{name}}' could not be loaded",
    "ERROR_CALCULATING_GRAPH_DIFFERENCE": "Error while calculating differences between graphs",
    "INCORRECT_GRAPH_CONFIGURATION": "Graph configuration error",
  },
};