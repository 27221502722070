export class OptimizationSettings {
  startDate: Date;

  endDate: Date;

  startMinDate: Date;

  startMaxDate: Date;

  endMinDate: Date;

  endMaxDate: Date;

  constructor() {
    this.startMinDate = new Date();
    this.startMinDate.setHours(0, 0, 0, 0); // use midnight local time.
    this.startMaxDate = new Date(this.startMinDate.getTime());
    //End date first day of actual_year + 4
    this.startMaxDate.setFullYear(this.startMaxDate.getFullYear() + 4, 0, 1);

    this.endMinDate = new Date(this.startMinDate.getTime());
    this.endMaxDate = new Date(this.startMaxDate.getTime());

    this.startDate = new Date(this.startMinDate.getTime());
    this.endDate = new Date(this.endMaxDate.getTime());
  }
}
