import { AdhocOptimizationService } from "./services/ad-hoc-optimization.service";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexModule } from "@angular/flex-layout";
import { BfcButtonModule } from "@bfl/components/button";
import { BfcCheckboxModule } from "@bfl/components/checkbox";
import { BfcDatepickerModule } from "@bfl/components/datepicker";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcInputModule } from "@bfl/components/input";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcSelectModule } from "@bfl/components/select";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcTranslationModule } from "@bfl/components/translation";
import Handsontable from "handsontable";
import frDict from "../config/translations/handsontable/fr-FR";
import { MaxValidatorDirective } from "./directives/max-validator.directive";
import { MinValidatorDirective } from "./directives/min-validator.directive";
import { OptimizationTableComponent } from "./optimization-table/optimization-table.component";
import { OptimizationComponent } from "./optimization.component";
import { OptimizationStatusDialogComponent } from "./status-dialog/optimization-status-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    BfcButtonModule,
    BfcCheckboxModule,
    BfcDatepickerModule,
    BfcDialogModule,
    BfcFormFieldModule,
    BfcFormModule,
    BfcInputModule,
    BfcLoadingModule,
    BfcSelectModule,
    BfcSelectModule,
    BfcSelectModule,
    BfcSinglePageLayoutModule,
    BfcTranslationModule,
  ],
  declarations: [
    OptimizationComponent,
    OptimizationTableComponent,
    MinValidatorDirective,
    MaxValidatorDirective,
    OptimizationStatusDialogComponent,
  ],
  providers:[
    AdhocOptimizationService,
  ],
  entryComponents: [
    OptimizationStatusDialogComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class OptimizationModule {

  constructor() {
    Handsontable.languages.registerLanguageDictionary(frDict);
  }

}
