import { AuthorizationGuard } from "./auth/auth-guard.service";
import { DiffCalculationService } from "./diff-calculation/diff-calculation.service";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BFC_TRANSLATIONS } from "@bfl/components/translation";
import { translations } from "../config/translations/translations";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { NotificationService } from "./notifications/notification.service";
import { PlotDataService } from "./plot-data/plot-data.service";
import { UserConfigurationService } from "./user-configuration/user-configuration.service";
import { GraphManagerService } from "./graph-manager/graph-manager.service";
import { ExportService } from "./export/export-service";

@NgModule({
  imports: [
    BfcNotificationModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    AuthorizationGuard,
    InitializerProvider,
    UserConfigurationService,
    NotificationService,
    PlotDataService,
    DiffCalculationService,
    GraphManagerService,
    ExportService,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
