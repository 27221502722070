<div matDialogTitle>{{ 'OPTIMIZATION.STATUS_DIALOG.TITLE' | bfcTranslate }}</div>
<mat-dialog-content>
    <div class="content" *ngIf="optimizationDataStatus.optimizationDataStatus && optimizationDataStatus.optimizationDataStatus.length > 0;else empty_content">
        <div fxLayout="row">
            <span fxFlex="0 0 10"></span>
            <span class="bold" fxFlex="0 0 12">
                {{"OPTIMIZATION.STATUS_DIALOG.QUEUE_NUMBER"|bfcTranslate}}
            </span>
            <span class="bold" fxFlex="0 0 23">
                {{"OPTIMIZATION.STATUS_DIALOG.POWERPLANT"|bfcTranslate}}
            </span>
            <span class="bold" fxFlex="0 0 25">
                {{"OPTIMIZATION.STATUS_DIALOG.REMAINING_TIME"|bfcTranslate}}
            </span>
            <span class="bold" fxFlex="0 0 30">
                {{"OPTIMIZATION.STATUS_DIALOG.STATE"|bfcTranslate}}
            </span>
        </div>
        <div *ngFor="let job of optimizationDataStatus.optimizationDataStatus" fxLayout="row" class="row">
            <div fxFlex="0 0 10" fxFlexAlign="center">
                <mat-checkbox color="primary" [(ngModel)]="job.checked" ngDefaultControl></mat-checkbox>
            </div>
            <span fxFlex="0 0 12" fxFlexAlign="center">
                {{job.position}}
            </span>
            <span fxFlex="0 0 23" fxFlexAlign="center">
                {{job.powerPlantName}}
            </span>
            <span fxFlex="0 0 25" fxFlexAlign="center">
                {{humanizeDuration(job.duration)}}
            </span>
            <span fxFlex="0 0 30" fxFlexAlign="center">
                {{job.optimizationState}}
            </span>
        </div>
        <div class="padding-top-half padding-bottom-half hint-text">
            {{"OPTIMIZATION.STATUS_DIALOG.HINT_TEXT"|bfcTranslate}}
        </div>
    </div>
    <ng-template #empty_content>
        <div class="content">
            {{"OPTIMIZATION.STATUS_DIALOG.EMPTY_TEXT"|bfcTranslate}}
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="action" fxLayout="column">
        <div fxLayout="row">
            <button mat-button color="primary" class="bfc-button-secondary" (click)="cancel()">
                {{ 'TANGENTO_COMMON.CANCEL' | bfcTranslate }}
            </button>
            <button mat-button color="primary" class="bfc-button-hero" [disabled]="!isAdhocEnabled() || optimizationDataStatus.optimizationDataStatus.length === 0"
                    (click)="cancelOptimization()">
                {{ 'OPTIMIZATION.STOP_OPTIMIZATION' | bfcTranslate }}
            </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="end" *ngIf="!isAdhocEnabled()">
            <span>{{ "OPTIMIZATION.AVAILABILTY_INFO" | bfcTranslate }}</span>
        </div>
    </div>
</mat-dialog-actions>
