export const languageOptionsFR = {
  downloadJPEG: "Enregistrer le diagramme au format JPEG",
  downloadPDF: "Enregistrer le diagramme au format PDF",
  downloadPNG: "Enregistrer le diagramme au format PNG",
  downloadSVG: "Enregistrer le diagramme au format SVG",
  months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  shortMonths: ["Janv", "Févr", "Mars", "Avril", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"],
  weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  decimalPoint: ",",
  printChart: "Imprimer le diagramme",
  rangeSelectorFrom: "De",
  rangeSelectorTo: "À",
};
