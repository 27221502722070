<bfc-single-page-layout-large>

    <bfe-b2b-navigation (routeRequest)="routeRequest($event)"
                        [navigationTree]="navigationTree"
                        *ngIf="navigationTree"></bfe-b2b-navigation>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

</bfc-single-page-layout-large>

