import { languageOptionsDE } from "./config/translations/graphs/language-options-de";
import { languageOptionsEN } from "./config/translations/graphs/language-options-en";
import { languageOptionsFR } from "./config/translations/graphs/language-options-fr";
import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST, BfcAuthenticationInterceptor, BfcAuthenticationModule } from "@bfl/components/authentication";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcTranslationModule, BfcTranslationService } from "@bfl/components/translation";
import * as Highstock from "highcharts/highstock";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { CoreModule } from "./core/core.module";
import { OptimizationModule } from "./optimization/optimization.module";
import { NavigationTreeService } from "./core/navigation/navigation-tree.service";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";
@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    BfcSinglePageLayoutLargeModule,
    MatIconModule,
    CoreModule,
    OptimizationModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    NavigationTreeService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private bfcTranslationService: BfcTranslationService,
    private domSanitizer: DomSanitizer,
  ) {
    matIconRegistry.addSvgIcon("key-icon", domSanitizer.bypassSecurityTrustResourceUrl("assets/img/key.svg"));
    matIconRegistry.addSvgIcon("filter-icon", domSanitizer.bypassSecurityTrustResourceUrl("assets/img/filter.svg"));
    this.configureHighcharts();
  }

  private configureHighcharts() {
    let languageOptions: any;
    switch (this.bfcTranslationService.language.toLocaleLowerCase()) {
      case "fr":
        languageOptions = languageOptionsFR;
        break;
      case "en":
        languageOptions = languageOptionsEN;
        break;
      default:
        languageOptions = languageOptionsDE;
    }
    Highstock.setOptions({
      time: {
        useUTC: false,
      },
      lang: languageOptions,
    });
  }
}
