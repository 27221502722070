import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BfcTranslationService } from "@bfl/components/translation";
import * as moment from "moment";
import { forkJoin, from, Observable, of, Subject } from "rxjs";
import {
  catchError,
  concatMap,
  distinct,
  finalize,
  flatMap,
  map,
  reduce,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { NotificationService } from "../core/notifications/notification.service";
import { AdHocOptimizationData } from "../model/adhoc-optimization-data";
import { OptimizationSettings } from "../model/optimization-settings";
import { AdHocConfiguration } from "../model/rest/adhoc-configuration";
import { AdhocQueueJob } from "../model/rest/adhoc-queue-job";
import { TimeSeriesOptimization } from "../model/rest/time-series-optimization";
import { adhocJobErrorStates, adhocJobUnfinishedStates } from "./models/adhoc-job-states";
import { OptimizationTableComponent } from "./optimization-table/optimization-table.component";
import { AdhocOptimizationService } from "./services/ad-hoc-optimization.service";
import { OptimizationStatusDialogComponent } from "./status-dialog/optimization-status-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-optimization",
  templateUrl: "./optimization.component.html",
  styleUrls: ["./optimization.component.scss"],
})
export class OptimizationComponent implements OnInit, OnDestroy {
  @ViewChild(OptimizationTableComponent)
  private optimizationTableComponent: OptimizationTableComponent;

  private readonly adhocJobUnfinishedStates = adhocJobUnfinishedStates;

  private readonly adhocJobErrorStates = adhocJobErrorStates;

  public optimizationForm: FormGroup;

  public configuration: AdHocConfiguration;

  public isAdhocJobRunning: boolean = false;

  public powerplants: string[] = [];

  public powerplant: string;

  public timeSeriesTypes: string[] = [];

  public timeSeriesType: string;

  public powerplantLayers: string[] = [];

  public unsubscribe: Subject<void> = new Subject<void>();

  public showLoadingIndicator = false;

  public seaLevels;

  public adhocData: AdHocOptimizationData;

  public settings: OptimizationSettings = new OptimizationSettings();

  constructor(
    private adhocOptimizationService: AdhocOptimizationService,
    private formBuilder: FormBuilder,
    private bfcTranslationService: BfcTranslationService,
    private notificationService: NotificationService,
    private optimizationStatusDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.showLoadingIndicator = true;
    this.adhocOptimizationService.loadAdHocConfiguration("Adhoc").subscribe((configuration: AdHocConfiguration) => {
      if (configuration.timeSeriesOptimizationList.length === 0) {
        this.showLoadingErrorNotification();
        return;
      }
      this.configuration = configuration;
      this.powerplants = this.adhocOptimizationService.getPowerPlantNames(this.configuration);
      this.powerplant = this.powerplants.length > 0 ? this.powerplants[0] : null;

      this.startAdhocQueuePolling();
      this.updateTimeSeriesTypes();

      let completeObservables = [this.getAdhocQueue(), this.updatePowerplantLayers(this.configuration)];
      this.disableLoadingIndicatorBuildFormOnAllCompleted(completeObservables);

    }, (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error("Error attempting to init view.", error);
      this.showLoadingErrorNotification();
    });
  }

  private showLoadingErrorNotification() {
    this.notificationService.showErrorMessage("ERROR.ERROR_FETCHING_CONFIGURATION");
    this.showLoadingIndicator = false;
  }

  onPowerplantSelected() {
    this.powerplant = this.optimizationForm.get("powerplant").value;
    this.showLoadingIndicator = true;
    this.seaLevels = undefined;
    this.adhocData = null;
    this.timeSeriesType = null;
    this.updateTimeSeriesTypes();

    let completeObservables = [this.getAdhocQueue(), this.updatePowerplantLayers(this.configuration)];
    this.disableLoadingIndicatorBuildFormOnAllCompleted(completeObservables);
  }

  private disableLoadingIndicatorBuildFormOnAllCompleted(completeObservables: Observable<any>[]) {
    forkJoin(completeObservables)
      .pipe(finalize(() => this.showLoadingIndicator = false))
      .subscribe(
        () => {
          this.buildForm();
        });
  }

  private buildForm() {
    this.optimizationForm = this.formBuilder.group({
      "powerplant": this.powerplant,
      "startdate": this.settings.startDate,
      "enddate": this.settings.endDate,
      "timeSeriesType": this.timeSeriesType,
      "seaLevelForm": this.createSeaLevelForm(),
    });
  }

  private startAdhocQueuePolling() {
    this.adhocOptimizationService.pollAllQueues(this.powerplants).pipe(
      flatMap((jobs: AdhocQueueJob[][]) => from(jobs)),
      map((jobs: AdhocQueueJob[]) => jobs.filter(job => this.powerplant === job.powerPlantName)),
      takeUntil(this.unsubscribe),
    ).subscribe(
      (filteredJobs: AdhocQueueJob[]) => {
        const isAdhocJobRunningBefore = this.isAdhocJobRunning;
        this.setIsAdhocJobRunning(filteredJobs);

        // When an adhoc was running before check and none is running after check then one ended
        if (isAdhocJobRunningBefore && !this.isAdhocJobRunning) {
          if (this.hasAdHocJobInFailureState(filteredJobs)) {
            this.notificationService.showErrorMessage("OPTIMIZATION.OPTIMIZATION_CANCELLED");
          } else {
            this.notificationService.showSuccessMessage("OPTIMIZATION.OPTIMIZATION_FINISHED");
          }
        }
      },
      (error: unknown) => {
        // eslint-disable-next-line no-console
        console.error("error polling all queues", error);
      },
    );
  }

  private updateTimeSeriesTypes() {
    this.timeSeriesTypes = this.adhocOptimizationService
      .getSelectableTimeSeriesTypes(this.powerplant, this.configuration);
  }

  private updatePowerplantLayers(configuration: AdHocConfiguration): Observable<any> {
    if (this.powerplant) {
      let seaLevelOptimizations = this.adhocOptimizationService
        .getSeaLevelOptimizations(this.powerplant, configuration);

      // Filter LayerNames aka timeSeriesName uniquiely
      return from(seaLevelOptimizations).pipe(
        map((layer: TimeSeriesOptimization) => layer.timeSeriesName),
        distinct(),
        reduce((all, current) => [...all, current], []),
        switchMap(powerplantLayers => {
          this.powerplantLayers = powerplantLayers;
          return this.adhocOptimizationService.getSeaLevels(seaLevelOptimizations, powerplantLayers);
        }),
        catchError(() => {
          this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_LOADING_SEA_LEVELS");
          return of(null);
        }),
        tap(seaLevels => this.seaLevels = seaLevels),
      );
    } else {
      this.powerplantLayers = [];
    }
  }

  private setIsAdhocJobRunning(jobs: AdhocQueueJob[]) {
    let isJobInUnfinishedState = jobs.find((data: AdhocQueueJob) => {
      return this.powerplant ===
        data.powerPlantName &&
        this.adhocJobUnfinishedStates.indexOf(data.optimizationState) > -1;
    });
    this.isAdhocJobRunning = !!isJobInUnfinishedState;
  }

  private hasAdHocJobInFailureState(jobs: AdhocQueueJob[]): boolean {
    // The newest job is first in queue, we can rely on that, backend confirmed that.
    const job = jobs[0];
    return this.powerplant === job.powerPlantName && this.adhocJobErrorStates.indexOf(job.optimizationState) > -1;
  }

  resetToEOD() {
    this.showLoadingIndicator = true;
    this.powerplant = this.optimizationForm.get("powerplant").value;

    this.adhocOptimizationService.loadAdHocConfiguration("EndOfDay").pipe(
      concatMap((eodConfiguration: AdHocConfiguration) => {
        let message: string = this.bfcTranslationService.translate("OPTIMIZATION.EOD_RESET_SEA_LEVEL");

        let completeSubjects = [this.updatePowerplantLayers(eodConfiguration)];

        // Reset table to eod only if data was previously loaded to table
        if (this.adhocData) {
          // only table data should be loaded, otherwise a subsequent save would try to store data in the endofday
          // instead of the adhoc model
          message += " " + this.bfcTranslationService.translate("OPTIMIZATION.EOD_RESET_TIMESERIE");
          completeSubjects.push(this.loadAdHocTimeSeries(eodConfiguration, true));
        }

        return forkJoin(completeSubjects).pipe(
          map(() => ({
            message: message,
          })),
          finalize(() => this.showLoadingIndicator = false));
      }),
    ).subscribe(({ message }) => {
      this.buildForm();
      this.notificationService.showTranslatedSuccessMessage(message);
    }, () => {
      this.notificationService.showErrorMessage("ERROR.ERROR_FETCHING_CONFIGURATION");
    });
  }

  loadAdHocTimeSeriesAsync(configuration: AdHocConfiguration) {
    this.timeSeriesType = this.optimizationForm.get("timeSeriesType").value;
    this.loadAdHocTimeSeries(configuration).subscribe(() => {
    });
  }

  loadAdHocTimeSeries(configuration: AdHocConfiguration, loadTableDataOnly: boolean = false): Observable<any> {
    let timeInterval = null;
    try {
      timeInterval = this.adhocOptimizationService.getTimeInterval(this.powerplant, this.timeSeriesType, configuration);
    } catch (error) {
      this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_LOADING_TIME_SERIES_INVALID_CONFIGURATION");
    }

    const uris = this.adhocOptimizationService.getGdmUris(this.powerplant, this.timeSeriesType, configuration);
    this.showLoadingIndicator = true;
    return this.adhocOptimizationService.loadAdhocTimeSeries(
      configuration,
      uris,
      this.settings.startDate,
      this.settings.endDate,
      timeInterval,
    ).pipe(
      finalize(() => this.showLoadingIndicator = false),
      tap(data => {
        if (loadTableDataOnly && this.adhocData !== null) {
          this.adhocData.tableData = data.tableData;
        } else {
          this.adhocData = data;
        }
      }), catchError(() => {
        this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_LOADING_TIME_SERIES");
        return of(null);
      }));
  }

  onDateChanged(date, type: string) {
    // This handles the case, when the user manually adds a date and the field is completely empty
    if (date) {
      if (type === "startDate") {
        this.settings.startDate = date;
        this.settings.endMinDate = date;
        if (this.settings.endDate < this.settings.endMinDate) {
          this.settings.endDate = this.settings.endMinDate;
        }
      }

      if (type === "endDate") {
        // set it to first hour of selected day
        date = moment(date).hour(1).toDate();
        this.settings.endDate = date;
        this.settings.startMaxDate = date;
      }
    }
  }

  onDateChangedReloadData() {
    // if table is present - reload data!
    // hint: startDate <= endDate check is used to catch invalid inputs (eg by typing into inputfield)
    if (
      this.settings.startDate != null &&
      this.settings.endDate != null &&
      this.settings.startDate <= this.settings.endDate &&
      this.adhocData != null
    ) {
      this.loadAdHocTimeSeries(this.configuration).subscribe(() => this.buildForm());
    }
  }

  saveTimeSeries() {
    // if no time series is loaded just save the sea levels
    this.powerplantLayers.forEach((powerplantLayer, index) => {
      this.seaLevels[powerplantLayer][0].timeSerie[0][1] = (this.optimizationForm.get("seaLevelForm") as FormArray).at(index).get("start").value;
      this.seaLevels[powerplantLayer][1].timeSerie[0][1] = (this.optimizationForm.get("seaLevelForm") as FormArray).at(index).get("end").value;
    });
    if (!this.adhocData) {
      if (this.optimizationForm.valid) {
        this.showLoadingIndicator = true;

        forkJoin(this.adhocOptimizationService.saveSeaLevels(this.adhocData, this.seaLevels))
          .pipe(finalize(() => this.showLoadingIndicator = false))
          .subscribe(() => {
            this.notificationService.showSuccessMessage("OPTIMIZATION.SUCCESSFULLY_SAVED_SEA_LEVELS");
          }, () => {
            this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_SAVING_SEA_LEVELS");
          });
      } else {
        this.markFormGroupTouched(this.optimizationForm);
      }
    } else {
      if (
        this.optimizationForm.valid &&
        this.optimizationTableComponent &&
        this.optimizationTableComponent.isDataValid()
      ) {
        let timeInterval = null;
        try {
          timeInterval = this.adhocOptimizationService.getTimeInterval(
            this.powerplant,
            this.timeSeriesType,
            this.configuration,
          );
        } catch (error) {
          this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_LOADING_TIME_SERIES_INVALID_CONFIGURATION");
        }
        if (timeInterval) {
          let observables = [];
          this.showLoadingIndicator = true;
          observables.push(...this.adhocOptimizationService.saveAdHocTimeSeries(
            this.adhocData,
            this.settings.startDate,
            this.settings.endDate,
            timeInterval,
          ));
          observables.push(...this.adhocOptimizationService.saveSeaLevels(this.adhocData, this.seaLevels));
          forkJoin(observables)
            .pipe(finalize(() => this.showLoadingIndicator = false))
            .subscribe(() => {
              this.notificationService.showSuccessMessage("OPTIMIZATION.SUCCESSFULLY_SAVED_SEA_LEVELS_AND_TIME_SERIES");
            }, () => {
              this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_SAVING_SEA_LEVELS_AND_TIME_SERIES");
            });
        }
      } else {
        if (!this.optimizationForm.valid) {
          this.markFormGroupTouched(this.optimizationForm);
        }
      }
    }
  }

  startOptimization() {
    if (this.optimizationForm.valid) {
      this.adhocOptimizationService.startOptimization(this.powerplant, this.settings.startDate, this.settings.endDate)
        .pipe(
          concatMap(() => {
            return this.getAdhocQueue();
          }),
        ).subscribe(() => {
          this.notificationService.showSuccessMessage("OPTIMIZATION.SUCCESSFULLY_STARTED_OPTIMIZATION");
        },
        (error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.message === "An Optimization is already running") {
              this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_OPTIMIZATION_ALREADY_RUNNING");
            } else {
              this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_STARTING_OPTIMIZATION");
            }
          }
        },
        );
    }
  }

  cancelOptimization() {
    this.adhocOptimizationService.cancelOptimization(this.powerplant)
      .pipe(
        concatMap( () => {
          return this.getAdhocQueue().pipe(
            catchError( () => {
              this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_CANCELLING_OPTIMIZATION");
              return of();
            }),
          );
        }),
      ).subscribe( () => {
        this.notificationService.showSuccessMessage("OPTIMIZATION.OPTIMIZATION_CANCELLED");
      });
  }

  getAdhocQueue(): Observable<any> {
    return this.adhocOptimizationService.loadQueue(this.powerplant).pipe(
      tap((data: AdhocQueueJob[]) => this.setIsAdhocJobRunning(data)),
      catchError(() => {
        this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_LOADING_QUEUE");
        return of(null);
      }));
  }

  openAdhocStatusDialog() {
    let allQueries = [];

    this.powerplants.forEach((powerplant) => {
      allQueries.push(this.adhocOptimizationService.loadQueue(powerplant));
    });

    /* eslint-disable */
    forkJoin(allQueries).pipe(map((results) => {
      let allJobs: AdhocQueueJob[] = [];
      results.forEach((adhocQueueJobs: AdhocQueueJob[]) => {
        adhocQueueJobs.forEach((adhocQueueJob: AdhocQueueJob) => {
          if (this.adhocJobUnfinishedStates.indexOf(adhocQueueJob.optimizationState) > -1) {
            allJobs.push(adhocQueueJob);
          }
        });
      });
      return allJobs;
    }),
    ).subscribe(
      (data) => {
        const optimizationStatusDialogRef = this.optimizationStatusDialog.open(OptimizationStatusDialogComponent, {
          data: { optimizationDataStatus: data, powerplants: this.powerplants },
        });

        optimizationStatusDialogRef.afterClosed().subscribe((adhocQueueJobs: AdhocQueueJob[]) => {
          let toCancelObservables = [];

          if (adhocQueueJobs && adhocQueueJobs.length > 0) {
            adhocQueueJobs.forEach((job: AdhocQueueJob) => {
              toCancelObservables.push(
                this.adhocOptimizationService.cancelOptimization(job.powerPlantName)
                  .pipe(
                    map(() => true),
                    catchError(() => of(false)),
                  ),
              );
            });

            forkJoin(toCancelObservables).subscribe(
              (responses: boolean[]) => {
                this.adhocOptimizationService.loadQueue(this.powerplant).subscribe(
                  (data: AdhocQueueJob[]) => {
                    this.setIsAdhocJobRunning(data);
                  });

                if (responses.every((response) => response)) {
                  this.notificationService.showSuccessMessage("OPTIMIZATION.ALL_OPTIMIZATIONS_CANCELLED");
                } else {
                  let message: string = "";
                  responses.forEach((response, index) => {
                    let currentJob = adhocQueueJobs[index];

                    if (response) {
                      message += this.bfcTranslationService.translate("OPTIMIZATION.OPTIMIZATION_CANCEL_SUCCESSFULL", {
                        powerplant: currentJob.powerPlantName,
                        position: "" + currentJob.position,
                      });
                    } else {
                      message += this.bfcTranslationService.translate("OPTIMIZATION.OPTIMIZATION_CANCEL_ERROR", {
                        powerplant: currentJob.powerPlantName,
                        position: "" + currentJob.position,
                      });
                    }
                  });
                  this.notificationService.showTranslatedWarningMessage(message);
                }
              }, () => {
                this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_CANCELLING_OPTIMIZATIONS");
              },
            );
          }
        });
      }, () => {
        this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_LOADING_QUEUE");
      },
    );
    /* eslint-enable */
  }

  /**
   * Adhoc Optimization is only available from 0800-1700
   * @returns {boolean}
   */
  isAdhocEnabled(): boolean {
    const now = new Date();
    return now.getHours() >= 8 && now.getHours() < 17;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private createSeaLevelForm() {
    const fields = this.powerplantLayers.map(plant => {
      return this.formBuilder.group({
        start: [
          (this.seaLevels[plant][0].timeSerie[0][1] * 100).toFixed(2),
          [Validators.required, Validators.maxLength(5), Validators.min(0), Validators.max(100), Validators.pattern("\\d+(\\.\\d{1,2})?")],
        ],
        end: [
          (this.seaLevels[plant][1].timeSerie[0][1] * 100).toFixed(2),
          [Validators.required, Validators.maxLength(5), Validators.min(0), Validators.max(100), Validators.pattern("\\d+(\\.\\d{1,2})?")],
        ],
      });
    });
    return this.formBuilder.array(fields);
  }

  getErrorMessageForSeaLevel(index: number, name: any): string {
    const field: AbstractControl = (this.optimizationForm.get("seaLevelForm") as FormArray).at(index).get(name);
    if (field.hasError("required")) {
      return "OPTIMIZATION.FORM_ERRORS.REQUIRED";
    } else if (field.hasError("min")) {
      return "OPTIMIZATION.FORM_ERRORS.MIN";
    } else if (field.hasError("max")) {
      return "OPTIMIZATION.FORM_ERRORS.MAX";
    } else {
      return "OPTIMIZATION.FORM_ERRORS.NUMBER";
    }
  }

  hasErrorsForSeaLevel(index: number, name: any) {
    return (this.optimizationForm.get("seaLevelForm") as FormArray).at(index).get(name).invalid;
  }
}
