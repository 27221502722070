import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationTreeService } from "./core/navigation/navigation-tree.service";

@Component({
  selector: "app-tangento",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  navigationTree: string;

  constructor(private router: Router,
    private navigationTreeService: NavigationTreeService) {
    this.getNavigationTree();
  }

  routeRequest(e): void {
    this.router.navigate([e.detail.path]);
  }

  private getNavigationTree(): void {
    this.navigationTreeService.getNavigationTree()
      .subscribe(navigationTree => this.navigationTree = navigationTree);
  }
}
