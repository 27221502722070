import { Injectable, NgZone } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";

import { PlotData } from "../../model/rest/plot-data";
import { GraphManagerService } from "../graph-manager/graph-manager.service";
import { ExportRequest } from "./export-request";

@Injectable()
export class ExportService {

  constructor(private bfcTranslationService: BfcTranslationService, private ngZone: NgZone) {
  }

  exportCSV(plotDataList: PlotData[], minDate?: number, maxDate?: number, showMeanMedian = false): Observable<boolean> {
    const subject = new Subject<any>();

    const worker = new Worker(new URL("./export.worker", import.meta.url), { type: "module" });

    const exportRequest: ExportRequest = {
      plotDataList: plotDataList,
      minDate: minDate,
      maxDate: maxDate,
      dateTitle: this.bfcTranslationService.translate("TANGENTO_COMMON.DATE"),
      meanLabel: this.bfcTranslationService.translate("MARGIN_PRICE.DATA_TABLE.MEAN"),
      medianLabel: this.bfcTranslationService.translate("MARGIN_PRICE.DATA_TABLE.MEDIAN"),
      showMeanMedian: showMeanMedian,
    };

    worker.onmessage = (message) => {
      const fileName = "Data_" + moment(new Date()).format("YYYYMMDD") + ".csv";
      const contentType = 'text/csv;charset=utf-8"';
      const csvContent = new Blob([message.data], { type: contentType });
      const csvFile = new File([csvContent], fileName, { type: contentType });
      saveAs(csvFile);
      subject.next();
      subject.complete();
    };

    let poppedFunctions = [];
    plotDataList.forEach(pd => {
      poppedFunctions.push(GraphManagerService.popDataGroupApproximation(pd));
    });
    worker.postMessage(exportRequest);
    plotDataList.forEach(pd => {
      GraphManagerService.pushDataGroupApproximation(pd, poppedFunctions.shift());
    });

    return subject;
  }

  exportAsZip(fileContent: string, fileName: string): Observable<boolean> {
    const subject = new Subject<any>();
    const jsZip = new JSZip();
    jsZip.file(fileName + ".csv", fileContent);
    jsZip.generateAsync({ type: "blob" })
      .then((content: Blob) => {
        this.ngZone.run(() => {  // jsZip.generateAsync does not return in "zone".
          saveAs(content, fileName + ".zip");
          subject.next();
          subject.complete();
        });
      });
    return subject;
  }

}
