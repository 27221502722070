import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import * as Highstock from "highcharts/highstock";
import { ChartLayout } from "../../model/chart-layout/chart-layout";
import { ChartSeries } from "../../model/chart-layout/chart-series";
import { ChartYAxisLayout } from "../../model/chart-layout/chart-y-axis-layout";
import { DashboardSettings } from "../../model/dashboard-settings";
import { DeltaDashboardSettings } from "../../model/delta-dashboard-settings";
import { FilterOption } from "../../model/filter-option";
import { GraphFilter } from "../../model/graph-filter";
import { ChartConfiguration } from "../../model/highcharts/chart-configuration.interface";
import { PlotData, Series, YAxis } from "../../model/rest/plot-data";
import { StandardDashboardSettings } from "../../model/standard-dashboard-settings";

/**
 * Provides several helper functions
 * This service does NOT need to be a singleton.
 */
@Injectable()
export class PlotDataService {

  constructor(private bfcTranslationService: BfcTranslationService) {
  }

  /**
     * Returns the earliest display date given plotData.
     * @param plotData
     * @returns {number}
     */
  getEarliestDate(plotData: PlotData): number {
    let firstTimestamps = [];

    plotData.series.forEach((serie: Series) => {
      if (serie.data && serie.data.length > 0) {
        const timestamps = serie.data.map(data => data[0]);
        firstTimestamps.push(Math.min(...timestamps));
      }
    });

    if (firstTimestamps.length > 0) {
      return Math.max(...firstTimestamps);
    } else {
      return 0;
    }
  }

  /**
     * Returns the latest display date given the plotData
     * @param plotData
     * @returns {number}
     */
  getLatestDate(plotData: PlotData): number {
    let max = 0;
    plotData.series.forEach((series: Series) => {
      const maxTimeStamp = series.data.length > 0 ? (series.data[series.data.length - 1])[0] : 0;
      max = Math.max(maxTimeStamp, max);
    });
    return max;
  }

  /**
     * Converts a data series to a chartSeries used for display in highcharts.
     * @param series
     * @returns ChartSeries
     */
  private static dataSeries2ChartSeries(series: Series): ChartSeries {
    // remove all content after the first occurence of '('
    const reformatedName = series.name.indexOf("(") > -1 ? series.name.substring(0, series.name.indexOf("(")) : series.name;

    return {
      name: reformatedName,
      yAxis: series.yAxis,
      type: series.plotType,
      dataLabels: {
        enabled: false,
      },
      dataGrouping: {
        forced: true,
        // TAN-257 force aggregation always to 1h or 24h
        units: [
          ["hour", [1, 24]],
        ],
        approximation: series.dataGroupingApproximation,
        enabled: !series.disableApproximation,
      },
      data: series.data,
      pointStart: series.pointStart,
      pointInterval: series.pointInterval,
      visible: series.visible,
      turboThreshold: 0,
      step: series.step ? series.step : false,
      color: series?.color,
      connectNulls: false,
    };
  }

  private combineYAxisLayoutWithData(layout: ChartYAxisLayout, plotData: PlotData): ChartYAxisLayout[] {
    return plotData.yAxis.map((axis: YAxis) => {
      let axisLayout: ChartYAxisLayout = JSON.parse(JSON.stringify((layout))); // create working copy
      axisLayout.opposite = axis.secondaryAxis;
      axisLayout.labels.format = axis.format;
      axisLayout.labels.align = axis.secondaryAxis ? "right" : "left";
      axisLayout.title.text = axis.label;
      axisLayout.title.rotation = axis.secondaryAxis ? 90 : 270;
      axisLayout.title.x = axis.secondaryAxis ? -axisLayout.title.x : axisLayout.title.x;
      return axisLayout;
    });
  }

  /**
     * Applies plotData to chartLayout returning a ChartConfiguration
     * @param chartLayout
     * @param yAxisLayout
     * @param plotData
     * @returns {ChartConfiguration}
     */
  combineLayoutWithData(
    chartLayout: ChartLayout,
    yAxisLayout: ChartYAxisLayout,
    plotData: PlotData,
  ): ChartConfiguration {
    const stacked: boolean = plotData.series.some(series => series.stacked);
    if (stacked) {
      const seriesType: string = this.getSeriesType(plotData.series);
      if (!chartLayout.plotOptions[seriesType]) {
        chartLayout.plotOptions[seriesType] = {};
      }
      chartLayout.plotOptions[seriesType].stacking = "normal";
    }
    const configuration: ChartConfiguration = JSON.parse(JSON.stringify((chartLayout))); // create working copy

    if (stacked) {
      configuration.legend.reversed = true;
    }

    configuration.yAxis = this.combineYAxisLayoutWithData(yAxisLayout, plotData);

    configuration.series = plotData.series.map(PlotDataService.dataSeries2ChartSeries);

    configuration.tooltip.formatter = function () {
      return this.points.map((point) => {
        const series = plotData.series[point.series.index];
        const value = `${point.y.toFixed(2)} ${series.unit}`;
        return `<span style="color:${series.color}">\u25CF</span>${series.name}: <b>${value}</b><br/>`;
      });
    };

    const highstock = Highstock;
    const translateService = this.bfcTranslationService;
    configuration.tooltip.formatter = function () {
      let s = '<div class="tooltip-header">'
                + translateService.translate("TANGENTO_COMMON.MEASUREMENT_POINT", {
                  date: highstock.dateFormat(translateService.translate("TOOLTIP.DATE_FORMAT"), this.x),
                })
                + "</div>";
      s += '<div class="tooltip-item-container">';

      // we split points in two groups to get two column layout.
      const group1 = this.points.splice(0, this.points.length / 2);
      const group2 = this.points;
      const pointGroups = [group1, group2];
      for (let group = 0; group < pointGroups.length; group++) {
        s += '<div class="tooltip-item-group">';
        for (let i = 0; i < pointGroups[group].length; i++) {
          const point = pointGroups[group][i];
          const series = plotData.series[point.series.index];
          const value = `${point.y.toFixed(2)} ${series.unit}`;
          s += '<div class="tooltip-item">';
          s += `<span>${point.series.name}</span> <span style="color:${series.color}">${value}</span>`;
          s += "</div>";
        }
        s += "</div>";
      }

      s += "</div>";
      return s;
    };
    configuration.tooltip.useHTML = true;

    // apply default colors only if none are provided!
    if (chartLayout.plotColors && chartLayout.plotColors.length >= configuration.series.length) {
      for (let i = 0; i < configuration.series.length && i < chartLayout.plotColors.length; i++) {
        if (!configuration.series[i].color) {
          configuration.series[i].color = chartLayout.plotColors[i];
        }
      }
    }

    return configuration;
  }

  /**
     * Applies a graphFilter in the plotData returning a new PlotData object
     * @param plotData
     * @param filter
     * @returns {PlotData}
     */
  applyGraphFilter(plotData: PlotData, filter: GraphFilter): PlotData {
    const isHidden = (s: Series) => {
      if (s.timeSeriesType === "Price" && s.xValueAggregation === "Summed") {
        return true; // we NEVER show summed prices. We need it for data aggregation, however. (@see: https://jira.bkw.ch/jira/browse/TAN-255)
      }
      if (s.positionType === "Financial" && filter.position === "Physical" ||
                s.positionType === "Physical" && filter.position === "Financial") {
        return true;
      } else if ((s.positionType === "Financial" || s.positionType === "Physical")
                && (s.xValueAggregation === "Summed" && filter.xValueAggregation === "Averaged" ||
                    s.xValueAggregation === "Averaged" && filter.xValueAggregation === "Summed")
      ) {
        return true;
      }
      return false;
    };

    const result: PlotData = new PlotData();
    result.title = plotData.title;

    let relevantSeries = plotData.series.filter(s => {
      return !isHidden(s);
    });

    result.series = [];
    relevantSeries.forEach(s => {
      const copy: Series = JSON.parse(JSON.stringify(s));
      copy.dataGroupingApproximation = s.dataGroupingApproximation; // function needs to be copied manually...
      result.series.push(copy); // we need to copy the entire series
    });

    result.yAxis = plotData.yAxis.filter(axis => {
      return result.series.some(s => s.yAxis === plotData.yAxis.indexOf(axis));
    });

    result.series.forEach(s => { // update indices of y-Axis.
      const yAxis = plotData.yAxis[s.yAxis];
      s.yAxis = result.yAxis.indexOf(yAxis);
    });

    result.errors = plotData.errors;

    return result;
  }

  /**
     * Uses per graph local start and end date for standard dashboard and updates dashboard setting
     * @param {PlotData} plotData
     * @param {DashboardSettings} dashboardSettings
     */
  updateStandardDashboardSettings(plotData: PlotData, dashboardSettings: DashboardSettings) {
    const earliest = this.getEarliestDate(plotData);
    const latest = this.getLatestDate(plotData);

    let startDate = dashboardSettings.startMinDate ?
      Math.min(dashboardSettings.startMinDate.getTime(), earliest) :
      earliest;
    let endDate = dashboardSettings.endMaxDate ? Math.max(dashboardSettings.endMaxDate.getTime(), latest) : latest;

    this.updateDashboardSettings(plotData, dashboardSettings, startDate, endDate);
  }

  /**
     * Uses global (for all graphs) start and end date for delta dashboard and updates dashboard setting
     * @param {PlotData} plotData
     * @param {DashboardSettings} dashboardSettings
     * @param globalMaxMin
     */
  updateDeltaDashboardSettings(plotData: PlotData, dashboardSettings: DashboardSettings, globalMaxMin) {
    let startDate = globalMaxMin.startDate;
    let endDate = globalMaxMin.endDate;
    this.updateDashboardSettings(plotData, dashboardSettings, startDate, endDate);
  }

  /**
     * Updates the dates stored in graphSettings as well as the filterOptions (new filteres may be added)
     * @param plotData
     * @param dashboardSettings
     * @param startDate
     * @param endDate
     */
  private updateDashboardSettings(plotData: PlotData, dashboardSettings: DashboardSettings, startDate, endDate) {
    if (startDate !== 0) {
      dashboardSettings.startMinDate = new Date(startDate);
      dashboardSettings.startDate = new Date(startDate);
      dashboardSettings.endMinDate = new Date(startDate);
    }
    dashboardSettings.startMaxDate = new Date(endDate);
    dashboardSettings.endDate = new Date(endDate);
    dashboardSettings.endMaxDate = new Date(endDate);

    const filterOptions = (dashboardSettings as DeltaDashboardSettings).filter ?
      (dashboardSettings as DeltaDashboardSettings).filter.filterOptions :
      (dashboardSettings as StandardDashboardSettings).filterOptions;

    plotData.series.forEach(s => {
      if (s.timeSeriesType && !filterOptions.some(o => {
        return o.key === s.timeSeriesType;
      })) {
        filterOptions.push(new FilterOption(s.timeSeriesType, true));
        filterOptions.sort((a, b) => a.key > b.key ? 1 : -1);
      }
    });
  }

  /**
     * Determines for all graphs (ie. given plotData) the global max and min date
     * @param {PlotData[]} plotData
     * @returns {{startDate: number, endDate: number}}
     */
  getGlobalMaxMin(plotData: PlotData[]) {
    let firstTimestamps = [];
    let lastTimestamps = [];

    plotData.forEach((plotDataInfo: PlotData) => {
      plotDataInfo.series.forEach((serie: Series) => {
        if (serie.earliestDate && serie.latestDate) {
          firstTimestamps.push(serie.earliestDate);
          lastTimestamps.push(serie.latestDate);
        }
      });
    });

    let startDate = Math.max(...firstTimestamps);
    let endDate = Math.min(...lastTimestamps);

    return { startDate, endDate };
  }

  /**
     * Evaluate the most common plotType of the series, if the plotType is not column, return series.
     * This value is required for the stacking plot options
     * @param series    list of series to evaluate the plotType
     * @return  "column", if "column" is the most common plotType, "series" otherwise
     */
  private getSeriesType(series: Series[]): string {
    const arr = series.slice().map(seriesRecord => seriesRecord.plotType);
    const type = arr.sort((a, b) =>
      arr.filter(v => v === a).length
            - arr.filter(v => v === b).length,
    ).pop();
    return type === "column" ? "column" : "series";
  }
}
