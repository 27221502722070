export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Energy Asset",
  "UNAUTHORIZED_COMPONENT": {
    "HEADING": "Keine Autorisierung",
    "MESSAGE": "Für das gewählte Kraftwerk fehlt die Berechtigung",
    "MESSAGE_1": "Bei Fragen oder Problemen wenden Sie sich bitte an folgenden Kontakt:",
    "CONTACT_NAME": "Dr. Anton Lüthi",
    "CONTACT_PHONE": "Direkt",
    "CONTACT_PHONE_LINK_NAME": "+41 58 477 50 75",
    "CONTACT_PHONE_LINK": "+41584775075",
    "CONTACT_MAIL": "anton.luethi2@bkw.ch",
  },
  "START_SCREEN": {
    "MARKET": "Markt: {{marketId}}",
    "TITLE": "Gesamtübersicht",
    "POWERPLANTS": "Kraftwerke - {{marketPlace}}",
  },
  "CONTACT_COMPONENT": {
    "HEADING": "Kontakt",
    "MESSAGE": "Bei Fragen oder Problemen wenden Sie sich bitte an folgenden Kontakt:",
    "CONTACT_NAME": "Dr. Anton Lüthi",
    "CONTACT_PHONE": "Direkt",
    "CONTACT_PHONE_LINK_NAME": "+41 58 477 50 75",
    "CONTACT_PHONE_LINK": "+41584775075",
    "CONTACT_MAIL": "anton.luethi2@bkw.ch",
  },
  "MARGIN_PRICE": {
    "DATA_TABLE": {
      "POWERPLANT": "KW Stufe",
      "CATEGORY": "Kategorie",
      "MEDIAN": "Median",
      "MEAN": "Mittelwert",
      "TITLE": "Grenzpreistabelle",
    },
  },
  "PORTFOLIO": {
    "GRAPH_TITLE": "Position {{index}} {{date}}",
    "GRAPH": {
      "DELTA_POS1_POS2": "Delta Pos 1 - Pos 2",
    },
  },
  "POWERPLANT_OVERVIEW": {
    "OVERVIEW": "Übersicht",
    "TSE_MODEL": "Kraftwerkstopologie",
  },
  "OPTIMIZATION": {
    "TITLE": "Adhoc Optimierung",
    "LOAD": "Lade Zeitreihen",
    "SAVE": "Speichere Zeitreihen",
    "START_OPTIMIZATION": "Start Optimierung",
    "STOP_OPTIMIZATION": "Stop Optimierung",
    "START": "Start",
    "END": "Ende",
    "RESET_TO_EOD": "Zurücksetzen auf EoD",
    "MARGINAL_CONDITIONS": "Randbedingungen der Optimierung [Seestände in %]",
    "OVERVIEW_AS_TABLE": "Zeitreihentabelle",
    "QUEUE": "Optimierungsstatus ansehen",
    "AVAILABILTY_INFO": "Nur 08:00 bis 17:00",
    "FORM_ERRORS": {
      "MIN": "Wert ist zu klein",
      "MAX": "Wert ist zu gross",
      "REQUIRED": "Bitte Wert eingeben",
      "NUMBER": "Bitte gültige Zahl eingeben",
    },
    "STATUS_DIALOG" : {
      "TITLE": "Aktuelle Rechenvorgänge",
      "QUEUE_NUMBER": "Queue-Nummer",
      "POWERPLANT": "Kraftwerk",
      "REMAINING_TIME": "geschätzte Optimierungszeit*",
      "STATE": "Status",
      "HINT_TEXT": "*Geschätzte Optimierungszeit ab Status RunningOptimization",
      "EMPTY_TEXT": "Zurzeit laufen keine Optimierungen",
    },
    "INVALID_VALUES_IN_TABLE": "Die Tabelle enthält ungültige Werte",
    "ERROR_LOADING_TIME_SERIES_INVALID_CONFIGURATION": "Fehler beim Laden der Zeitreihen. Die Konfiguration ist fehlerhaft.",
    "ERROR_LOADING_SEA_LEVELS": "Fehler beim Laden der Seestände.",
    "SUCCESSFULLY_SAVED_SEA_LEVELS_AND_TIME_SERIES": "Seestände und Daten erfolgreich gespeichert.",
    "SUCCESSFULLY_SAVED_SEA_LEVELS": "Seestände erfolgreich gespeichert.",
    "ERROR_SAVING_SEA_LEVELS_AND_TIME_SERIES": "Fehler beim Speichern der Seestände und Daten",
    "ERROR_SAVING_SEA_LEVELS": "Fehler beim Speichern der Seestände",
    "ERROR_PASTING_DATA_INVALID": "Fehler beim Einfügen der Daten. Die eingefügten Daten enthalten ungültige Werte",
    "ERROR_PASTING_OUT_OF_BOUND": "Fehler beim Einfügen der Daten. Die eingefügte Datenmenge ist grösser als die Tabelle.",
    "ERROR_PASTING_TIMESTAMPS_MUST_BE_IDENTICAL": "Fehler beim Einfügen der Daten. Die Zeitstempel sind unterschiedlich.",
    "TABLE_HEADER_TIMESTAMP": "Datum / Uhrzeit",
    "SUCCESSFULLY_STARTED_OPTIMIZATION": "Optimierung erfolgreich gestartet",
    "ERROR_STARTING_OPTIMIZATION": "Fehler beim Start der Optimierung",
    "ERROR_OPTIMIZATION_ALREADY_RUNNING": "Für dieses Kraftwerk läuft bereits eine Optimierung",
    "OPTIMIZATION_CANCELLED": "Optimierung abgebrochen",
    "ALL_OPTIMIZATIONS_CANCELLED": "Optimierungen abgebrochen",
    "OPTIMIZATION_CANCEL_ERROR": "{{powerplant}} Job (Nummer {{position}}) konnte nicht gestoppt werden.",
    "OPTIMIZATION_CANCEL_SUCCESSFULL": "{{powerplant}} Job (Nummer {{position}}) erfolgreich gestoppt.",
    "ERROR_CANCELLING_OPTIMIZATION": "Die Optimierung konnte nicht abgebrochen werden",
    "ERROR_CANCELLING_OPTIMIZATIONS": "Die Optimierungen konnten nicht abgebrochen werden",
    "ERROR_LOADING_TIME_SERIES": "Fehler beim Laden der Zeitreihen",
    "ERROR_LOADING_QUEUE": "Fehler beim Laden des Optimierungsstatus",
    "OPTIMIZATION_FINISHED": "Optimierung abgeschlossen",
    "EOD_RESET_SEA_LEVEL": "Die Seestände wurden auf EoD zurückgesetzt.",
    "EOD_RESET_TIMESERIE": "Die geladene Zeitreihe wurde auf EoD zurückgesetzt. Sie können sie mit \"Speichere Zeitreihe\" sichern.",
    "TABLE_TYPE_HEADING": "Zeitreihentabelle - {{kw}}",
    "SELECT_POWERPLANT": "Kraftwerk auswählen",
    "START_DATE": "Startdatum",
    "END_DATE": "Enddatum",
    "SELECT_TIMESERIES_CATEGORY": "Zeitreihen Kategorie",
  },
  "TANGENTO_COMMON": {
    "DASHBOARD": {
      "PRODUCTION": "Erzeugung",
      "MARGINPRICES": "Grenzpreise",
      "STORAGE": "Speicher und Zufluss",
      "OVERVIEW": "Kraftwerksübersicht",
    },
    "TIME_RANGE_BUTTONS": {
      "HOUR": "Stunde",
      "DAY": "Tag",
      "WEEK": "Woche",
      "MONTH": "Monat",
      "YEAR": "Jahr",
    },
    "DATE": "Datum",
    "AGGREGATION": "Aggregation",
    "OPTIONS": "Optionen",
    "DATA_STATUS": "Datenstand",
    "MEASUREMENT_POINT": "Datum {{date}}",
    "ERROR_LOADING_DATA": "Beim Laden der Daten ist ein Fehler aufgetreten.",
    "CHANGE_DATA": "Datenstand ändern",
    "SAVE": "Speichern",
    "CANCEL": "Abbrechen",
    "SELECT": "Auswählen",
    "CONFIRM": "Bestätigen",
    "RESET_ALL_FILTERS": "alle Filter zurücksetzen",
    "AGGREGATIONS": {
      "HOUR": "Stunde",
      "DAY": "Tag",
      "WEEK": "Woche",
      "MONTH": "Monat",
      "QUARTER": "Quartal",
      "YEAR": "Jahr",
    },
    "CSV": "CSV",
    "FILTER": {
      "PHYSICALLY": "Physisch",
      "FINANCIALLY": "Finanziell",
      "AMOUNTS_SUMS": "Mengen / Summen",
      "PERFORMANCE_MEAN": "Leistung / Mittelwert",
      "GROUPED": "gruppiert",
      "PILED": "gestapelt",
      "DISPLAY": "Darstellung",
      "POSITION_TITLE": "Position",
      "DATA_COLLECTION": "Datenberechnung",
    },
    "POSITION": "Pos {{number}}",
    "DATE_FROM": "Von",
    "DATE_TO": "Bis",
  },
  "GLOBAL_NAVIGATION": {
    "DASHBOARD": {
      "MARGINPRICES": "Grenzpreise",
      "OVERVIEW": "Übersicht",
      "PRODUCTION": "Erzeugung",
      "STORAGE": "Speicher und Zufluss",
    },
    "PORTFOLIO": "Portfolio",
  },
  "DATA_STATUS_DIALOG_COMPONENT": {
    "TITLE": "Datenstand auswählen",
    "CALCULATION": "Rechnung",
    "ADHOC": "Adhoc",
  },
  "BKWFC_CHART_MODULE": {
    "TIME_RANGE_BUTTONS": {
      "1_MONTH": "1 Monat",
      "6_MONTHS": "6 Monate",
      "1_YEAR": "1 Jahr",
      "1_WEEK": "1 Woche",
      "1_DAY": "1 Tag",
      "ALL": "Alles",
    },
    "HIGHSTOCK_DATETIME_FORMAT": {
      "millisecond": "%H:%M:%S.%L",
      "second": "%H:%M:%S",
      "minute": "%H:%M",
      "hour": "%H:%M",
      "day": "%e. %b",
      "week": "%e. %b",
      "month": "%b %y",
      "year": "%Y",
    },
    "EXPORT_AS_PNG": "PNG",
  },
  "TIME_SERIES_CATEGORY": {
    "STORAGE": "Speicher",
    "INFLOWS": "Zuflüsse",
    "CONSTRAINTS": "Einschränkungen",
    "PRICE": "Preis",
    "OPERATIONALRESTRICTIONS": "Maschineneinschränkungen",
  },
  "TIME_SERIES_TYPE": {
    "TIMESERIES": "Zeitreihe",
    "PRICE": "Preis",
    "CONSTRAINT": "Einschränkung",
    "NOTDEFINED": "Standard",
    "POSITION": "Position",
  },
  "TOOLTIP": {
    "DATE_FORMAT": "%d.%m.%y %H:%M",
  },
  "ERROR": {
    "ERROR_CODE": {
      "NO_DATA_FOR_VERSION_DATE": "Zu diesem Datum sind keine Daten vorhanden",
      "TIMEOUT": "Timeout bei der Netzwerkkommunikation",
    },
    "ERROR_FETCHING_GRAPH": "Fehler beim Laden des Graphen",
    "ERROR_FETCHING_TABLE": "Fehler beim Laden der Tabelle",
    "ERROR_FETCHING_CONFIGURATION": "Fehler beim Laden der Konfiguration",
    "ERROR_FETCHING_TSE_MODEL": "Fehler beim Laden der Kraftwerkstopologie",
    "COMMON_ERROR_MESSAGE": "Es ist ein Fehler aufgetreten",
    "SOME_TIME_SERIES_NOT_LOADED": "Die Zeitreihe '{{name}}' konnte nicht geladen werden",
    "ERROR_CALCULATING_GRAPH_DIFFERENCE": "Fehler bei der Berechnung der Graphendifferenz",
    "INCORRECT_GRAPH_CONFIGURATION": "Die Graphkonfiguration ist fehlerhaft.",
  },
};