export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Energy Asset",
  "UNAUTHORIZED_COMPONENT": {
    "HEADING": "Pas d'autorisation",
    "MESSAGE": "Pas de permission pour la centrale sélectionnée",
    "MESSAGE_1": "Si vous avez des questions ou problèmes, veuillez contacter:",
    "CONTACT_NAME": "Dr. Anton Lüthi",
    "CONTACT_PHONE": "Tél",
    "CONTACT_PHONE_LINK_NAME": "+41 58 477 50 75",
    "CONTACT_PHONE_LINK": "+41584775075",
    "CONTACT_MAIL": "anton.luethi2@bkw.ch",
  },
  "START_SCREEN": {
    "MARKET": "Marché: {{marketId}}",
    "TITLE": "Vue d’ensemble complète",
    "POWERPLANTS": "Centrales électriques - {{marketPlace}}",
  },
  "CONTACT_COMPONENT": {
    "HEADING": "Contact",
    "MESSAGE": "Si vous avez des questions ou problèmes, veuillez contacter:",
    "CONTACT_NAME": "Dr. Anton Lüthi",
    "CONTACT_PHONE": "Tél",
    "CONTACT_PHONE_LINK_NAME": "+41 58 477 50 75",
    "CONTACT_PHONE_LINK": "+41584775075",
    "CONTACT_MAIL": "anton.luethi2@bkw.ch",
  },
  "MARGIN_PRICE": {
    "DATA_TABLE": {
      "POWERPLANT": "Centrale de production",
      "CATEGORY": "Catégorie",
      "MEDIAN": "médian",
      "MEAN": "moyenne",
      "TITLE": "Tableau prix marginaux",
    },
  },
  "PORTFOLIO": {
    "GRAPH_TITLE": "Position {{index}} {{date}}",
    "GRAPH": {
      "DELTA_POS1_POS2": "Delta Pos 1 - Pos 2",
    },
  },
  "POWERPLANT_OVERVIEW": {
    "OVERVIEW": "Vue d’ensemble",
    "TSE_MODEL": "Topologie de la centrale électrique",
  },
  "OPTIMIZATION": {
    "TITLE": "Optimisation ad hoc",
    "LOAD": "Charger des séries temporelles",
    "SAVE": "Sauvegarder séries temporelles",
    "START_OPTIMIZATION": "Démarrer optimisation",
    "STOP_OPTIMIZATION": "Arrêter l’optimisation",
    "START": "Début",
    "END": "Fin",
    "RESET_TO_EOD": "Repasser à EoD",
    "MARGINAL_CONDITIONS": "Contraintes de l’optimisation [niveaux d’eau en %]",
    "OVERVIEW_AS_TABLE": "Table des séries temporelles",
    "QUEUE": "Voir l’état d’optimisation",
    "AVAILABILTY_INFO": "Seulement de 08:00 à 17:00",
    "FORM_ERRORS": {
      "MIN": "Valeur trop basse",
      "MAX": "Valeur trop élevée",
      "REQUIRED": "Saisir valeur",
      "NUMBER": "Saisir un chiffre valable",
    },
    "STATUS_DIALOG" : {
      "TITLE": "Processus de calcul en cours",
      "QUEUE_NUMBER": "Numéro de queue",
      "POWERPLANT": "Centrale électrique",
      "REMAINING_TIME": "Estimation du temps d’optimisation*",
      "STATE": "Statut",
      "HINT_TEXT": "*Estimation du temps d’optimisation à partir du statut RunningOptimization",
      "EMPTY_TEXT": "Aucune optimisation en cours",
    },
    "INVALID_VALUES_IN_TABLE": "La table contient des valeurs invalides",
    "ERROR_LOADING_TIME_SERIES_INVALID_CONFIGURATION": "Erreur lors du chargement des séries temporelles. La configuration est erronée.",
    "ERROR_LOADING_SEA_LEVELS": "Erreur lors du chargement des niveaux de remplissage.",
    "SUCCESSFULLY_SAVED_SEA_LEVELS_AND_TIME_SERIES": "Niveaux de remplissage et données des réservoirs enregistrés.",
    "SUCCESSFULLY_SAVED_SEA_LEVELS": "Niveaux de remplissage des réservoirs enregistrés.",
    "ERROR_SAVING_SEA_LEVELS_AND_TIME_SERIES": "Erreur lors de l'enregistrement des niveaux de remplissage et des séries temporelles",
    "ERROR_SAVING_SEA_LEVELS": "Erreur lors de l'enregistrement des niveaux de remplissage",
    "ERROR_PASTING_DATA_INVALID": "Erreur lors de l'insertion des données; les données contiennent des valeurs non valables",
    "ERROR_PASTING_OUT_OF_BOUND": "Erreur lors de l'insertion des données; le nombre de données est plus grand que la table.",
    "ERROR_PASTING_TIMESTAMPS_MUST_BE_IDENTICAL": "Erreur lors de l'insertion des données; l'horodatage ne correspond pas.",
    "TABLE_HEADER_TIMESTAMP": "Date / heure",
    "SUCCESSFULLY_STARTED_OPTIMIZATION": "Optimisation lancée avec succès",
    "ERROR_STARTING_OPTIMIZATION": "Erreur lors du démarrage de l'optimisation",
    "ERROR_OPTIMIZATION_ALREADY_RUNNING": "Une optimisation est déjà en cours pour cette unité",
    "OPTIMIZATION_CANCELLED": "Optimisation interrompue",
    "ALL_OPTIMIZATIONS_CANCELLED": "Optimisations annulée",
    "OPTIMIZATION_CANCEL_ERROR": "Le job n° {{position}} de l'unité {{powerplant}} n'a pas pu être interrompu.",
    "OPTIMIZATION_CANCEL_SUCCESSFULL": "Job nº {{position}} de l'unité {{powerplant}} interrompu avec succès.",
    "ERROR_CANCELLING_OPTIMIZATION": "L'optimisation n'a pas pu être interrompue",
    "ERROR_CANCELLING_OPTIMIZATIONS": "Les optimisations n'ont pas pu être interrompues",
    "ERROR_LOADING_TIME_SERIES": "Erreur lors du chargement des séries temporelles",
    "ERROR_LOADING_QUEUE": "Erreur lors du chargement du statut d'optimisation",
    "OPTIMIZATION_FINISHED": "Optimisation terminée",
    "EOD_RESET_SEA_LEVEL": "Les réservoirs ont été réinitialisés à EoD",
    "EOD_RESET_TIMESERIE": "La séries temporelle chargée a été réinitialisée à EoD. Vous pouvez la sauvegarder avec 'Sauvegarder série temporelle'.",
    "TABLE_TYPE_HEADING": "Table des séries temporelles - {{kw}}",
    "SELECT_POWERPLANT": "Choisir centrale électrique",
    "START_DATE": "Date de début",
    "END_DATE": "Date de fin",
    "SELECT_TIMESERIES_CATEGORY": "Catégorie des séries temporelles",
  },
  "TANGENTO_COMMON": {
    "DASHBOARD": {
      "PRODUCTION": "Production",
      "MARGINPRICES": "Prix marginaux",
      "STORAGE": "Réservoir et apports",
      "OVERVIEW": "Vue d'ensemble de l'unité de production",
    },
    "TIME_RANGE_BUTTONS": {
      "HOUR": "Heure",
      "DAY": "Jour",
      "WEEK": "Semaine",
      "MONTH": "Mois",
      "YEAR": "Année",
    },
    "DATE": "Date",
    "AGGREGATION": "Agrégation",
    "OPTIONS": "Options",
    "DATA_STATUS": "Ensemble de données",
    "MEASUREMENT_POINT": "Date {{date}}",
    "ERROR_LOADING_DATA": "Erreur lors du chargement des données.",
    "CHANGE_DATA": "Changer l'état des données",
    "SAVE": "Sauvegarder",
    "CANCEL": "Annuler",
    "SELECT": "Sélectionner",
    "CONFIRM": "Confirmer",
    "RESET_ALL_FILTERS": "Restrictions",
    "AGGREGATIONS": {
      "HOUR": "Heure",
      "DAY": "Jour",
      "WEEK": "Semaine",
      "MONTH": "Mois",
      "QUARTER": "Trimestre",
      "YEAR": "Année",
    },
    "CSV": "CSV",
    "FILTER": {
      "PHYSICALLY": "physique",
      "FINANCIALLY": "financière",
      "AMOUNTS_SUMS": "Volumes / Sommes",
      "PERFORMANCE_MEAN": "Puissance/Valeur moyenne",
      "GROUPED": "groupé",
      "PILED": "empilé",
      "DISPLAY": "Présentation",
      "POSITION_TITLE": "Position",
      "DATA_COLLECTION": "Calcul de données",
    },
    "POSITION": "Pos {{number}}",
    "DATE_FROM": "De",
    "DATE_TO": "À",
  },
  "GLOBAL_NAVIGATION": {
    "DASHBOARD": {
      "MARGINPRICES": "Prix marginaux",
      "OVERVIEW": "Vue d’ensemble",
      "PRODUCTION": "Production",
      "STORAGE": "Réservoir et apports",
    },
    "PORTFOLIO": "Portefeuille",
  },
  "DATA_STATUS_DIALOG_COMPONENT": {
    "TITLE": "Choisir un ensemble de données",
    "CALCULATION": "Calcul",
    "ADHOC": "Ad hoc",
  },
  "BKWFC_CHART_MODULE": {
    "TIME_RANGE_BUTTONS": {
      "1_MONTH": "1 Mois",
      "6_MONTHS": "(fr) 6 Monate",
      "1_YEAR": "1 Année",
      "1_WEEK": "(fr) 1 Woche",
      "1_DAY": "1 Jour",
      "ALL": "(fr) Alles",
    },
    "HIGHSTOCK_DATETIME_FORMAT": {
      "millisecond": "%H:%M:%S.%L",
      "second": "%H:%M:%S",
      "minute": "%H:%M",
      "hour": "%H:%M",
      "day": "%e. %b",
      "week": "%e. %b",
      "month": "%b %y",
      "year": "%Y",
    },
    "EXPORT_AS_PNG": "PNG",
  },
  "TIME_SERIES_CATEGORY": {
    "STORAGE": "Réservoir",
    "INFLOWS": "Apports",
    "CONSTRAINTS": "Restrictions",
    "PRICE": "Prix",
    "OPERATIONALRESTRICTIONS": "Restrictions des machines",
  },
  "TIME_SERIES_TYPE": {
    "TIMESERIES": "Série temporelle",
    "PRICE": "Prix",
    "CONSTRAINT": "Restriction",
    "NOTDEFINED": "Standard",
    "POSITION": "Position",
  },
  "TOOLTIP": {
    "DATE_FORMAT": "%d.%m.%y %H:%M",
  },
  "ERROR": {
    "ERROR_CODE": {
      "NO_DATA_FOR_VERSION_DATE": "Aucune donnée disponible pour cette date",
      "TIMEOUT": "Timeout lors de la communication réseau",
    },
    "ERROR_FETCHING_GRAPH": "Erreur lors du chargement des diagrammes",
    "ERROR_FETCHING_TABLE": "Erreur lors du chargement de la table",
    "ERROR_FETCHING_CONFIGURATION": "Erreur lors du chargement de la configuration",
    "ERROR_FETCHING_TSE_MODEL": "Erreur lors du chargement de la topologie de l'untié de production",
    "COMMON_ERROR_MESSAGE": "Une erreur est survenue",
    "SOME_TIME_SERIES_NOT_LOADED": "La séries temporelle '{{name}}' n'a pas pu être chargée",
    "ERROR_CALCULATING_GRAPH_DIFFERENCE": "Erreur lors du calcul des différences entre diagrammes",
    "INCORRECT_GRAPH_CONFIGURATION": "La configuraiton du diagramme est erronée.",
  },
};