import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import * as moment from "moment";
import { from, interval, Observable } from "rxjs";
import { map, reduce, switchMap } from "rxjs/operators";
import { AdHocOptimizationData } from "../../model/adhoc-optimization-data";
import { AdHocOptimizationTableData } from "../../model/adhoc-optimization-table-data";
import { AdHocConfiguration } from "../../model/rest/adhoc-configuration";
import { AdhocQueueJob } from "../../model/rest/adhoc-queue-job";
import { AdhocTimeSeriesData } from "../../model/rest/adhoc-time-series-data";
import { AdhocTimeSeriesRequest } from "../../model/rest/adhoc-time-series.request";
import { SaveAdhocTimeSeriesRequest } from "../../model/rest/save-adhoc-time-series-request";
import { StartOptimizationRequest } from "../../model/rest/start-optimization-request";
import { TimeInterval } from "../../model/rest/time-interval";
import { TimeSeriesOptimization } from "../../model/rest/time-series-optimization";
import { SeaLevel } from "../../model/sea-level";
import { InvalidAdHocConfigurationError } from "../errors/invalid-ad-hoc-configuration-error";
import { binarySearch } from "../helper/binary-search";

@Injectable()
export class AdhocOptimizationService {
  // Predefined by backend
  private seaLevelStartDate = new Date();

  private seaLevelEndDate = new Date();

  private seaLevelTimeInterval: TimeInterval = { timeInterval: "Day", timeIntervalMultiplier: 1 };

  constructor(private httpClient: HttpClient, private bfcConfigurationService: BfcConfigurationService) {
    this.seaLevelStartDate.setUTCHours(23, 0, 0, 0);
    this.seaLevelStartDate.setUTCFullYear(2016, 11, 31);
    this.seaLevelEndDate.setUTCHours(0, 0, 0, 0);
    this.seaLevelEndDate.setUTCFullYear(2017, 0, 1);
  }

  public loadAdHocConfiguration(optimizationType: string): Observable<AdHocConfiguration> {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/adhoc-configuration";
    return this.httpClient.get(url).pipe(
      map((adHocConfiguration: AdHocConfiguration) => {
        // we are only interested in "AdHoc" configurations.
        adHocConfiguration.timeSeriesOptimizationList = adHocConfiguration.timeSeriesOptimizationList.filter(t => {
          return t.optimizationType === optimizationType;
        });
        return adHocConfiguration;
      }),
    );
  }

  public saveSeaLevels(data: AdHocOptimizationData, seaLevels: SeaLevel): Observable<any>[] {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/adhoc-timeseries/saveOne";
    let seaLevelTimeSeriesData: AdhocTimeSeriesData[] = [];

    for (let seaLevel in seaLevels) {
      if (seaLevels.hasOwnProperty(seaLevel)) {
        seaLevels[seaLevel].forEach((timeSeriesOptimization: TimeSeriesOptimization) => {
          let timeSerieClone = JSON.parse(JSON.stringify(timeSeriesOptimization.timeSerie));

          // Convert sea level from percent to absolute number
          timeSerieClone[0][1] = timeSerieClone[0][1] / 100;

          seaLevelTimeSeriesData.push({
            gdmUri: timeSeriesOptimization.gdmUri,
            timeSerie: timeSerieClone,
          });
        });
      }
    }

    const observables = [];

    seaLevelTimeSeriesData.forEach((row: AdhocTimeSeriesData) => {
      const seaLevelSaveReq = new SaveAdhocTimeSeriesRequest(
        row,
        this.seaLevelStartDate,
        this.seaLevelEndDate,
        this.seaLevelTimeInterval,
      );
      observables.push(this.httpClient.post(url, seaLevelSaveReq));
    });
    return observables;
  }

  public saveAdHocTimeSeries(data: AdHocOptimizationData,
    startDate: Date,
    endDate: Date,
    timeInterval: TimeInterval): Observable<any>[] {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/adhoc-timeseries/saveOne";

    if (!data || data.tableData.length === 0) {
      throw Error("Cannot save empty time series");
    }
    // adHocOptimizationData is mapped to SaveAdhocTimeSeriesRequest
    let adhocTimeSeriesData: AdhocTimeSeriesData[] = [];

    data.tableData.forEach((row: AdHocOptimizationTableData) => {
      for (let key in data.tableData[0]) {
        if (data.tableData[0].hasOwnProperty(key) && key !== "TIMESTAMP" && key !== "TIMESTAMP_FORMATTED") {
          const modelUri = data.seriesNameToUriMap[key];
          let series = adhocTimeSeriesData.find(s => s.gdmUri === modelUri);
          if (!series) {
            series = { gdmUri: modelUri, timeSerie: [] };
            adhocTimeSeriesData.push(series);
          }
          series.timeSerie.push([row.TIMESTAMP, row[key]]);
        }
      }
    });
    const observables = [];
    adhocTimeSeriesData.forEach((row: AdhocTimeSeriesData) => {
      const adhocTimeSeriesReq = new SaveAdhocTimeSeriesRequest(row, startDate, endDate, timeInterval);
      observables.push(this.httpClient.post(url, adhocTimeSeriesReq));
    });

    return observables;
  }

  public loadAdhocTimeSeries(adHocConfiguration: AdHocConfiguration,
    gdmUris: string[],
    startDate: Date,
    endDate: Date,
    timeInterval: TimeInterval): Observable<AdHocOptimizationData> {

    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/adhoc-timeseries";
    const adhocTimeSeriesReq = new AdhocTimeSeriesRequest(gdmUris, startDate, endDate, timeInterval);
    return this.httpClient.post<AdhocTimeSeriesData[]>(url, adhocTimeSeriesReq).pipe(
      map(data => {

        // Rest data is converted to AdHocOptimization data which is more suitable for the client.
        const tableData: AdHocOptimizationTableData[] = [];
        const seriesNameToUriMap = {};
        const tableColumnValidations = {};

        data[0].timeSerie.forEach(d => {
          tableData.push({
            "TIMESTAMP": d[0],
            "TIMESTAMP_FORMATTED": moment(d[0]).format("DD.MM.YYYY HH:mm (Z)"),
          }); // first we create the table rows.
        });
        const findIndexOfTimeStamp = (timeStamp: number) => {
          return binarySearch(tableData, timeStamp, (el: number, arrayElement: AdHocOptimizationTableData) => {
            return el > arrayElement.TIMESTAMP ? 1 :
              el < arrayElement.TIMESTAMP ? -1 : 0;
          });
        };
        data.forEach((adhocTimeSeriesData: AdhocTimeSeriesData) => {
          adhocTimeSeriesData.timeSerie.forEach(t => {
            const indexOfTimeStamp = findIndexOfTimeStamp(t[0]);
            const timeSeriesOptimization = adHocConfiguration.timeSeriesOptimizationList.find(opt => {
              return opt.gdmUri.indexOf(adhocTimeSeriesData.gdmUri) > -1; // string match 'a bit' but not completely...
            });
            seriesNameToUriMap[timeSeriesOptimization.timeSeriesName] = adhocTimeSeriesData.gdmUri;
            tableColumnValidations[timeSeriesOptimization.timeSeriesName] = timeSeriesOptimization.timeSeriesCheckType;
            tableData[indexOfTimeStamp][timeSeriesOptimization.timeSeriesName] = t[1];
          });
        });
        return { tableData, seriesNameToUriMap, tableColumnValidations };
      }),
    );
  }

  public startOptimization(powerplantName: string,
    startDate: Date,
    endDate: Date): Observable<AdhocQueueJob> {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/optimization/start";
    const startOptimizationRequest: StartOptimizationRequest = {
      powerplantName: powerplantName,
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    };
    return this.httpClient.post<AdhocQueueJob>(url, startOptimizationRequest);
  }

  public cancelOptimization(powerplantName: string): Observable<any> {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/optimization/" + powerplantName + "/cancellation";
    return this.httpClient.post(url, {});
  }

  public loadQueue(powerplantName: string): Observable<AdhocQueueJob[]> {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/optimization/" + powerplantName + "/queue";
    return this.httpClient.get<AdhocQueueJob[]>(url);
  }

  public pollAllQueues(powerPlantNames: string[]): Observable<AdhocQueueJob[][]> {
    // Polling Interval of one minute
    const pollingInterval = 1000 * 60;

    return interval(pollingInterval).pipe(
      switchMap(() =>
        from(powerPlantNames).pipe(
          switchMap((powerPlantName: string) => this.loadQueue(powerPlantName)),
        ),
      ),
      reduce((list, current) => [...list, current], []),
    );
  }

  public getPowerPlantNames(adHocConfiguration: AdHocConfiguration): string[] {
    return adHocConfiguration.timeSeriesOptimizationList.map((t: TimeSeriesOptimization) => {
      return t.powerPlant.name;
    }).filter((value, index, self) => self.indexOf(value) === index);
  }

  public getSeaLevelOptimizations(
    powerplant: string,
    adHocConfiguration: AdHocConfiguration,
  ): TimeSeriesOptimization[] {
    return adHocConfiguration.timeSeriesOptimizationList.filter(t => {
      // 'storageEnd' and 'StorageStart' indicate that this timeSeriesOptimization contains a powerplant layer name.
      return t.powerPlant.name === powerplant &&
        (t.timeSeriesType === "StorageEnd" || t.timeSeriesType === "StorageStart");
    }).filter((value, index, self) => self.indexOf(value) === index);
  }

  public getSeaLevels(timeSeriesOptimizations: TimeSeriesOptimization[], powerplantLayers): Observable<any> {
    const gdmUris = timeSeriesOptimizations.map((timeSeriesOptimization: TimeSeriesOptimization) => {
      return timeSeriesOptimization.gdmUri;
    });

    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/adhoc-timeseries";
    const adhocTimeSeriesReq = new AdhocTimeSeriesRequest(
      gdmUris,
      this.seaLevelStartDate,
      this.seaLevelEndDate,
      this.seaLevelTimeInterval,
    );

    return this.httpClient.post(url, adhocTimeSeriesReq).pipe(
      // Match fetched adhocTimeSeries to timeSeries Optimizations (we need the values from adhocTimeSeries
      map((adhocTimeSeries: AdhocTimeSeriesData[]) => {
        timeSeriesOptimizations.forEach((timeSeriesOptimization: TimeSeriesOptimization) => {
          adhocTimeSeries.forEach((adhocTimeSerie: AdhocTimeSeriesData) => {
            // We cannot do exact matching here because gdmUris differ a little bit
            if (timeSeriesOptimization.gdmUri.includes(adhocTimeSerie.gdmUri)) {
              timeSeriesOptimization.timeSerie = adhocTimeSerie.timeSerie;
            }
          });
        });
        return timeSeriesOptimizations;
      }),
      map(enrichedTimeSeriesOptimizations => {
        let seaLevels = {};

        powerplantLayers.forEach(powerPlantLayer => {
          let matchingTimeSeriesOptimizations = enrichedTimeSeriesOptimizations.filter((timeSeriesOptimization) => {
            return timeSeriesOptimization.timeSeriesName === powerPlantLayer;
          });

          //Make sure TimeSeries Optimzation of Type "StorageStart" is always at first in array
          if (matchingTimeSeriesOptimizations[0].timeSeriesType !== "StorageStart") {
            let temp = matchingTimeSeriesOptimizations[1];

            matchingTimeSeriesOptimizations[1] = matchingTimeSeriesOptimizations[0];
            matchingTimeSeriesOptimizations[0] = temp;
          }

          seaLevels[powerPlantLayer] = matchingTimeSeriesOptimizations;
        });
        return seaLevels;
      }),
    );
  }

  public getSelectableTimeSeriesTypes(powerplant: string, adHocConfiguration: AdHocConfiguration): string[] {
    return adHocConfiguration.timeSeriesOptimizationList.filter(t => {
      return t.powerPlant.name === powerplant && (t.timeSeriesType !== "StorageEnd" && t.timeSeriesType !== "StorageStart");
    }).map((t: TimeSeriesOptimization) => {
      return t.timeSeriesType;
    }).filter((value, index, self) => self.indexOf(value) === index);
  }

  public getGdmUris(powerplant: string, timeSeriesType: string, adHocConfiguration: AdHocConfiguration): string[] {
    return adHocConfiguration.timeSeriesOptimizationList.filter(t => {
      return t.powerPlant.name === powerplant && t.timeSeriesType === timeSeriesType;
    }).map(t => {
      return t.gdmUri;
    });
  }

  public getTimeInterval(
    powerplant: string,
    timeSeriesType: string,
    adHocConfiguration: AdHocConfiguration,
  ): TimeInterval {
    const timeIntervals = adHocConfiguration.timeSeriesOptimizationList.filter(t => {
      return t.powerPlant.name === powerplant && t.timeSeriesType === timeSeriesType;
    }).map(t => {
      return t.timeInterval;
    });
    if (timeIntervals.length === 1) {
      return timeIntervals[0];
    } else if (timeIntervals.length === 0) {
      return null;
    } else {
      const first = timeIntervals[0];
      timeIntervals.forEach(t => {
        if (t.timeInterval !== first.timeInterval || t.timeIntervalMultiplier !== first.timeIntervalMultiplier) {
          throw new InvalidAdHocConfigurationError("No unique / unambiguous  time interval found for this powerplant and timeSeriesType. Please check the adhocConfiguration");
        }
      });
      return first;
    }
  }
}
