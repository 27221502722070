import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import { saveAs } from "file-saver";
import Handsontable from "handsontable";
import "handsontable/languages/de-CH";
import * as moment from "moment";
import { ExportService } from "../../core/export/export-service";
import { NotificationService } from "../../core/notifications/notification.service";
import { binarySearch } from "../helper/binary-search";

@Component({
  selector: "app-optimization-table",
  templateUrl: "./optimization-table.component.html",
  styleUrls: ["./optimization-table.component.scss"],
})
export class OptimizationTableComponent implements OnInit, OnChanges {
  private DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm (Z)";

  public showLoadingIndicator = false;

  private invalidTableFields = new Set<{ row: number, column: number }>();

  @Input()
  public data: any;

  @Input()
  public colValidations: any;

  private hotTable: Handsontable;

  private positivNumberValidator = (value, callback) => {
    if (value >= 0) {
      callback(true);
      return true;
    } else {
      this.onInvalidTableData();
      callback(false);
      return false;
    }
  };

  private negativeNumberValidator = (value, callback) => {
    if (value < 0) {
      callback(true);
      return true;
    } else {
      this.onInvalidTableData();
      callback(false);
      return false;
    }
  };

  constructor(private exportService: ExportService,
    private bfcTranslationService: BfcTranslationService,
    private notificationService: NotificationService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  ngOnInit(): void {
    if (this.data && this.colValidations) {
      this.createTable();
    }
  }

  ngOnChanges(): void {
    this.createTable();
  }

  private createTable() {
    this.showLoadingIndicator = true;
    const colsHeaders = [];
    const columns = [];
    colsHeaders.push(this.bfcTranslationService.translate("OPTIMIZATION.TABLE_HEADER_TIMESTAMP"));

    columns.push({
      data: "TIMESTAMP_FORMATTED",
      readOnly: true,
      type: "date",
      dateFormat: this.DATE_TIME_FORMAT,
    });
    for (let key in this.data[0]) {
      if (this.data[0].hasOwnProperty(key) && key !== "TIMESTAMP" && key !== "TIMESTAMP_FORMATTED") {
        colsHeaders.push(key);

        let validator;

        if (this.colValidations[key] === "PositiveNumbersOnly") {
          validator = this.positivNumberValidator;
        } else if (this.colValidations[key] === "NegativeNumbersOnly") {
          validator = this.negativeNumberValidator;
        }

        columns.push({
          data: key,
          validator: validator,
          allowInvalid: true,
          invalidCellClassName: "myInvalidClass",
          type: "numeric",
          numericFormat: {
            pattern: "0.00",
          },
          format: "0.[000000000000]",
        });
      }
    }

    const container = document.getElementById("hots-table-container");
    if (this.hotTable) {
      this.hotTable.destroy();
      this.invalidTableFields = new Set<{ row: number, column: number }>();
    }
    this.hotTable = new Handsontable(container, {
      licenseKey: this.bfcConfigurationService.configuration.handsonTableLicenseKey,
      language: this.getHandsontableLanguage(),
      data: this.data,
      beforeValidate: (value, row, prop) => {
        const column = this.hotTable.propToCol(prop);
        this.invalidTableFields.forEach((fieldValue) => {
          if (fieldValue.row == row && fieldValue.column == column) {
            this.invalidTableFields.delete(fieldValue);
          }
        });
      },
      afterValidate: (isValid, value, row, prop) => {
        if (!isValid) {
          const column = this.hotTable.propToCol(prop);
          this.invalidTableFields.add({ row: row, column: column });
        }
      },
      beforePaste: (pasteData: any[][], coords) => {
        this.doPaste(pasteData, coords, colsHeaders, columns);
        return false; // => handontable will not paste.
      },
      colHeaders: colsHeaders,
      columns: columns,
      columnSorting: true,
      columnHeaderHeight: 36,
      rowHeights: 36,
      fillHandle: {
        autoInsertRow: false,
      },
      fixedColumnsLeft: 1,
      copyPaste: true,
      height: 500,
      filters: true,
      dropdownMenu: ["filter_by_condition", "filter_by_value", "filter_action_bar"],
    });
    this.showLoadingIndicator = false;
  }

  private doPaste(pasteData: any[][],
    coords: { startCol: number, startRow: number }[],
    colsHeaders: string[],
    columns: { type: string, readOnly: boolean, validator?: any }[]) {
    this.showLoadingIndicator = true;

    const selected = this.hotTable.getSelected();
    const startCol = selected ? selected[0][1] : coords[0].startCol;
    const selectedRow = selected ? selected[0][0] : coords[0].startRow;
    setTimeout(() => {
      // determine if pasted data contains column header with time stamp column
      let containsHeaderWithTimestampColumn = false;
      let indexTimeStampColumn: number;
      for (let columnIndex = 0; columnIndex < pasteData[0].length; columnIndex++) {
        const matchingColumn = colsHeaders.find(f => f === pasteData[0][columnIndex]);
        if (matchingColumn && matchingColumn === colsHeaders[0]) {
          containsHeaderWithTimestampColumn = true;
          indexTimeStampColumn = columnIndex;
          break;
        }
      }

      // helper methods to map from index of pasted data to index or column name in table data.
      const getColumnName = (pastedDataColumnIndex) => {
        return containsHeaderWithTimestampColumn ?
          colsHeaders.find(f => f === pasteData[0][pastedDataColumnIndex]) :
          colsHeaders[pastedDataColumnIndex + startCol];
      };
      const getColumn = (pastedDataColumnIndex) => {
        const columnName = getColumnName(pastedDataColumnIndex);
        return columns[colsHeaders.indexOf(columnName)];
      };
      const findIndexOfTimeStamp = (timeStamp: number) => {
        return binarySearch(this.data, timeStamp, (el: number, arrayElement) => {
          return el > arrayElement.TIMESTAMP ? 1 :
            el < arrayElement.TIMESTAMP ? -1 : 0;
        });
      };

      const startRow = containsHeaderWithTimestampColumn ? selectedRow - 1 : selectedRow;

      // validate input data
      for (let row = containsHeaderWithTimestampColumn ? 1 : 0; row < pasteData.length; row++) {
        for (let columnIndex = 0; columnIndex < pasteData[row].length; columnIndex++) {
          const column = getColumn(columnIndex);

          if (columnIndex + startCol >= columns.length) {
            this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_PASTING_OUT_OF_BOUND");
            this.showLoadingIndicator = false;
            return;
          }

          if (column.validator) {
            if (!column.validator(pasteData[row][columnIndex], () => {
            })) {
              this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_PASTING_DATA_INVALID");
              this.showLoadingIndicator = false;
              return;
            }
          }
          if (column.readOnly && column.type === "date") { // validate timestamp
            const timeStamp = isNaN(pasteData[row][columnIndex]) ?
              moment(pasteData[row][columnIndex], this.DATE_TIME_FORMAT).valueOf() :
              Number(pasteData[row][columnIndex]);
            if (containsHeaderWithTimestampColumn) {
              const indexTimeStamp = findIndexOfTimeStamp(timeStamp);
              if (indexTimeStamp < 0) { // timestamp not found in table data.
                this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_PASTING_TIMESTAMPS_MUST_BE_IDENTICAL");
                this.showLoadingIndicator = false;
                return;
              }
            } else {
              // eslint-disable-next-line max-len
              const physicalRow = this.hotTable.toPhysicalRow(row + startRow); // sorting results in display columns being different from physical columns
              if (timeStamp !== this.data[physicalRow].TIMESTAMP) {
                this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_PASTING_TIMESTAMPS_MUST_BE_IDENTICAL");
                this.showLoadingIndicator = false;
                return;
              }
            }
          }
        }
      }

      // transfer data to table
      for (let row = containsHeaderWithTimestampColumn ? 1 : 0; row < pasteData.length; row++) {
        const timeStamp = isNaN(pasteData[row][indexTimeStampColumn]) ?
          moment(pasteData[row][indexTimeStampColumn], this.DATE_TIME_FORMAT).valueOf() :
          Number(pasteData[row][indexTimeStampColumn]);
        const indexOfTimeStampRow = containsHeaderWithTimestampColumn ? findIndexOfTimeStamp(timeStamp) : null;
        for (let columnIndex = 0; columnIndex < pasteData[row].length; columnIndex++) {
          const columnName = getColumnName(columnIndex);
          const column = getColumn(columnIndex);
          if (!column.readOnly) { // only update not-readonly cells
            if (containsHeaderWithTimestampColumn) {
              this.data[indexOfTimeStampRow][columnName] = pasteData[row][columnIndex];
            } else {
              // eslint-disable-next-line max-len
              const physicalRow = this.hotTable.toPhysicalRow(row + startRow); // sorting results in display columns being different from physical columns
              if (this.data[physicalRow]) {
                this.data[physicalRow][columnName] = pasteData[row][columnIndex];
              } else {
                this.notificationService.showErrorMessage("OPTIMIZATION.ERROR_PASTING_OUT_OF_BOUND");
                this.showLoadingIndicator = false;
                return;
              }
            }
          }
        }
      }
      setTimeout(() => {
        this.hotTable.render();
      });
      this.showLoadingIndicator = false;
    });
  }

  private onInvalidTableData(): void {
    this.notificationService.showErrorMessage("OPTIMIZATION.INVALID_VALUES_IN_TABLE");
  }

  private getHandsontableLanguage(): string {
    let lang: string = this.bfcTranslationService.language;

    const langMap = {
      de: "de-CH",
      en: "en-US",
      fr: "fr-FR",
    };
    return langMap[lang];
  }

  exportCSV() {
    let exportPlugin = this.hotTable.getPlugin("exportFile");
    const fileName = "Optimization_" + moment(new Date()).format("YYYYMMDD");
    let csv = exportPlugin.exportAsString("csv", {
      columnHeaders: true,
      columnDelimiter: ";",
      filename: "optimization-table.csv",
    });

    // This is a bug in handsontable, most of the header title have a double quote before and after,
    // we filter that out here
    // See: https://github.com/handsontable/handsontable/issues/4795 (closed, but this line is still required)
    csv = csv.replace(/"/g, "");

    saveAs(new Blob([csv], { type: "text/csv" }), fileName + ".csv");
  }

  public isDataValid() {
    return this.invalidTableFields.size === 0;
  }
}
