<bfc-loading-overlay [loading]="showLoadingIndicator">
    <div fxLayout="row" class="padding-bottom">
        <div class="headline">{{ "OPTIMIZATION.TITLE" | bfcTranslate }}</div>
        <button mat-button color="primary" type="button" (click)="openAdhocStatusDialog()">
            <span>{{ 'OPTIMIZATION.QUEUE' | bfcTranslate }}</span>
        </button>
    </div>
    <div class="optimization-form-container" *ngIf="optimizationForm">
        <form name="optimizationForm" [formGroup]="optimizationForm">
            <div fxLayout="row" fxLayoutAlign="start end">
                <mat-form-field fxFlex="0 0 50" class="bfc-padding-right-4">
                    <mat-label>{{ 'OPTIMIZATION.SELECT_POWERPLANT' | bfcTranslate }}</mat-label>
                    <mat-select formControlName="powerplant" [(ngModel)]="powerplant"
                                (selectionChange)="onPowerplantSelected()">
                        <mat-option *ngFor="let powerplant of powerplants" [value]="powerplant">
                            {{ powerplant }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div fxFlex="0 0 50" fxLayoutAlign="end end">
                    <mat-form-field class="bfc-padding-right">
                        <mat-label>{{ 'OPTIMIZATION.START_DATE' | bfcTranslate }}</mat-label>
                        <input matInput
                               formControlName="startdate"
                               (ngModelChange)="onDateChanged($event, 'startDate')"
                               (dateChange)="onDateChangedReloadData()"
                               [min]="settings.startMinDate"
                               [max]="settings.startMaxDate"
                               [matDatepicker]="startDatePicker">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="bfc-padding-right">
                        <mat-label>{{ 'OPTIMIZATION.END_DATE' | bfcTranslate }}</mat-label>
                        <input matInput
                               formControlName="enddate"
                               (ngModelChange)="onDateChanged($event, 'endDate')"
                               (dateChange)="onDateChangedReloadData()"
                               [min]="settings.endMinDate"
                               [max]="settings.endMaxDate"
                               [matDatepicker]="endDatePicker">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                    <div class="button-row button-formfield-fix">
                        <button mat-button [disabled]="!isAdhocEnabled()" color="primary" class="bfc-button-hero"
                                (click)="startOptimization()" *ngIf="!isAdhocJobRunning">
                            <span>{{ 'OPTIMIZATION.START_OPTIMIZATION' | bfcTranslate }}</span>
                        </button>
                        <button mat-button [disabled]="!isAdhocEnabled()" color="warn" class="bfc-button-hero"
                                (click)="cancelOptimization()" *ngIf="isAdhocJobRunning">
                            <span>{{ 'OPTIMIZATION.STOP_OPTIMIZATION' | bfcTranslate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="!isAdhocEnabled()" class="adhoc-enabled">
                <span>{{ "OPTIMIZATION.AVAILABILTY_INFO" | bfcTranslate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between end">
                <div class="section-headline">
                    {{ "OPTIMIZATION.MARGINAL_CONDITIONS" | bfcTranslate }}
                </div>
                <div>
                    <button mat-button color="primary" (click)="saveTimeSeries()">
                        <bfe-icon name="bfc-icon-arrowup" class="icon-left"></bfe-icon>
                        <span class="bfc-padding-left">{{ 'OPTIMIZATION.SAVE' | bfcTranslate }}</span>
                    </button>
                    <button mat-button color="primary" (click)="resetToEOD()">
                        <span>{{ 'OPTIMIZATION.RESET_TO_EOD' | bfcTranslate }}</span>
                    </button>
                </div>
            </div>
            <div class="bfc-padding-top marginal-conditions" fxLayout="column">
                <div fxLayout="row" *ngIf="seaLevels">
                    <ng-container *ngFor="let powerplantLayer of powerplantLayers; let i = index"
                                  formArrayName="seaLevelForm">
                        <div class="margin-condition" fxLayout="column" formGroupName="{{ i }}">
                            <mat-form-field class="bfc-padding-top">
                                <mat-label>{{powerplantLayer}} {{'OPTIMIZATION.START' | bfcTranslate }}</mat-label>
                                <input matInput
                                       maxLength="5"
                                       min="0"
                                       max="100"
                                       formControlName="start"
                                       type="text"
                                       #seaLevelStart
                                       required>
                                <span matSuffix class="postfix">
                                        <span class="invisible">{{ seaLevelStart.value }}</span>
                                        <span *ngIf="seaLevelStart.value">%</span>
                                    </span>
                                <mat-hint class="error">
                                    <mat-error
                                            *ngIf="hasErrorsForSeaLevel(i, 'start')">{{getErrorMessageForSeaLevel(i, 'start') | bfcTranslate}}</mat-error>
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="padding-top">
                                <mat-label>{{powerplantLayer}} {{ 'OPTIMIZATION.END' | bfcTranslate }}</mat-label>
                                <input matInput
                                       maxLength="5"
                                       min="0"
                                       max="100"
                                       formControlName="end"
                                       type="text"
                                       #seaLevelEnd
                                       required>
                                <span matSuffix class="postfix">
                                        <span class="invisible">{{ seaLevelEnd.value }}</span>
                                        <span *ngIf="seaLevelEnd.value">%</span>
                                    </span>
                                <mat-hint class="error">
                                    <mat-error
                                            *ngIf="hasErrorsForSeaLevel(i, 'end')">{{getErrorMessageForSeaLevel(i, 'end') | bfcTranslate}}</mat-error>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div fxLayout="column wrap" class="section-headline bfc-padding-top bfc-padding-bottom">
                <div fxFlex="0 0 100" class="padding-bottom">
                    {{ 'OPTIMIZATION.TABLE_TYPE_HEADING' | bfcTranslate: {kw: this.powerplant} }}
                </div>
                <div fxLayout="row" class="bfc-padding-top">
                    <mat-form-field fxFlex="0 0 50">
                        <mat-label>{{ 'OPTIMIZATION.SELECT_TIMESERIES_CATEGORY' | bfcTranslate }}</mat-label>
                        <mat-select formControlName="timeSeriesType"
                                    (selectionChange)="loadAdHocTimeSeriesAsync(configuration)">
                            <mat-option *ngFor="let timeSeriesType of timeSeriesTypes" [value]="timeSeriesType">
                                {{ "TIME_SERIES_CATEGORY." + timeSeriesType.toUpperCase() | bfcTranslate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="padding-bottom-2">
                <app-optimization-table *ngIf="adhocData" [data]="adhocData.tableData"
                                        [colValidations]="adhocData.tableColumnValidations"></app-optimization-table>
            </div>
        </form>
    </div>
</bfc-loading-overlay>