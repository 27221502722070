import { Observable } from "rxjs";
import { GraphManagerError } from "./graph-manager-error";

/**
 * PlotData contains all the required information to display a time series graph
 */
export class PlotData {
  title?: string;

  series: Series[];

  yAxis: YAxis[];

  errors: GraphManagerError[];
}

export class YAxis {
  format: string; // data format e.g. "{value} kW"

  label: string; // label for axis

  unit: string;

  secondaryAxis?: boolean; // is secondary axis of graph (then show right)
}

export class Series {
  name: string;

  plotType?: string; // e.g. "line"

  pointPadding?: number;

  groupPadding?: number;

  step?: boolean;

  stacked?: boolean;

  unit?: string;

  data: number[][]; // array of pairs, [timestamp, value] e.g. [1, 2], [2, 6]...

  pointStart: number; // start time

  pointInterval?: number; // interval between time points in ms.

  visible?: boolean; // indicator if the series is currently shown in the graph.

  color?: string; // the color of the time series.

  lineWidth?: number;

  borderWidth?: number;

  pointWidth?: number;

  borderColor?: string;

  yAxis: number; // refers to an index in the yAxis array.

  loadDataAsync?: () => Observable<Series>; // function to load data async (e.g. per REST)

  timeSeriesType?: "TimeSeries" | "Price" | "Position" | "Constraint" | "NotDefined";

  positionType: "Physical" | "Financial" | "Undefined";

  xValueAggregation: "Summed" | "Averaged" | "High" | "Low";

  // eslint-disable-next-line max-len
  dataGroupingApproximation: string | ((numbers: number[]) => any); // not delivered by server, used when zooming in highcharts

  disableApproximation: boolean;

  earliestDate:number;

  latestDate:number;
}
