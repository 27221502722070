import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { from, Observable, throwError } from "rxjs";
import { catchError, filter, find, flatMap, map, shareReplay, toArray } from "rxjs/operators";
import { NotificationService } from "../notifications/notification.service";
import { Powerplant } from "./interfaces/powerplant.interface";
import { UserConfiguration } from "./interfaces/user-configuration.interface";
import { VisualizationConfiguration } from "./interfaces/visualization-configuration.interface";

@Injectable()
export class UserConfigurationService {

  private userConfiguration: Observable<UserConfiguration>;

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private notificationService: NotificationService) {
  }

  getUserConfiguration(): Observable<UserConfiguration> {
    if (!this.userConfiguration) {
      const apiUrl: string = this.bfcConfigurationService.configuration.myApiUrl;
      this.userConfiguration = this.httpClient.get<UserConfiguration>(apiUrl + "services/configuration")
        .pipe(
          shareReplay({
            refCount: false,
            bufferSize: 1 },
          ),
          catchError((error: unknown) => {
            this.notificationService.showErrorMessage("ERROR.ERROR_FETCHING_CONFIGURATION");
            return throwError(error);
          }),
        );
    }
    return this.userConfiguration;
  }

  /**
     * Filters out all Powerplants that should be shown on start screen. Those are
     * powerplants that belong to dashboard 'Overview' and are real powerplants that
     * means no portfolios
     * @returns {Observable<Powerplant[]>}
     */
  getStartScreenPowerPlants(): Observable<Powerplant[]> {
    return this.getUserConfiguration()
      .pipe(
        map(userConfiguration => userConfiguration.visualizationConfigurationList),
        flatMap(visConfig => from(visConfig)),
        filter(visConfig => visConfig.powerPlant.name !== "Portfolio"), // portfolio is a magic string. Powerplants with name 'Portfolio' are actually not powerplants.
        filter(visConfig => visConfig.dashboard === "Overview"), // Overview is a magic string. Powerplants which belong to dashboard overview should be shown on start screen.
        map(visConfig => visConfig.powerPlant),
        toArray(),
        map(list => { // remove duplicates
          return list.filter((elem: Powerplant, index) => {
            const indexFirstOccurence = list.findIndex(p => {
              return p.id === elem.id;
            });
            return index === indexFirstOccurence;
          });
        }),
      );
  }

  getPowerplantId(market: string, powerplant: string): Observable<string> {
    return this.getUserConfiguration()
      .pipe(
        map((userConfiguration: UserConfiguration) => userConfiguration.visualizationConfigurationList),
        flatMap((visConfig: any) => from(visConfig)),
        find((visConfig: any) => (visConfig.market === market && visConfig.powerPlant.name === powerplant)),
        map((matchingConfig: any) => matchingConfig.powerPlant.id),
      );
  }

  hasAdhocTimeSeries(market: string, powerplant: string, dashboard: string): Observable<boolean> {
    return this.getUserConfiguration()
      .pipe(
        map(userConfiguration => userConfiguration.visualizationConfigurationList),
        flatMap(visConfig => from(visConfig)),
        map(visConfig => {
          return {
            market: visConfig.market,
            dashboard: visConfig.dashboard,
            powerplant: visConfig.powerPlant ? visConfig.powerPlant.name : "",
            graphId: visConfig.graphConfiguration.id,
            optimizationType: visConfig.optimizationType,
          };
        }),
        filter(config =>
          (config.market === market && config.powerplant === powerplant && config.dashboard === dashboard)),
        filter(config => config.optimizationType === "Adhoc"),
        toArray(),
        map(adhocConfigs => adhocConfigs.length > 0),
      );
  }

  getGraphIds(market: string, powerplant: string, dashboard: string, adhoc: boolean): Observable<string[]> {
    return this.getUserConfiguration()
      .pipe(
        map(userConfiguration => userConfiguration.visualizationConfigurationList),
        flatMap(visConfig => from(visConfig)),
        map(visConfig => {
          return {
            market: visConfig.market,
            dashboard: visConfig.dashboard,
            powerplant: visConfig.powerPlant ? visConfig.powerPlant.name : "",
            graphId: visConfig.graphConfiguration.id,
            optimizationType: visConfig.optimizationType,
          };
        }),
        filter(config =>
          (config.market === market && config.powerplant === powerplant && config.dashboard === dashboard)),
        filter(config => {
          if (adhoc) {
            return config.optimizationType === "Adhoc";
          } else {
            return config.optimizationType === "EndOfDay";
          }
        }),
        map(matchingConfig => matchingConfig.graphId),
        toArray(),
      );
  }

  getGraphIdForPortfolio(market: string): Observable<string> {
    return this.getUserConfiguration()
      .pipe(
        map(userConfiguration => {
          const visualizationConfiguration = this.getPortfolioConfig(userConfiguration, market);
          return visualizationConfiguration?.graphConfiguration.id;
        }),
      );
  }

  getPortfolioConfig(configuration: UserConfiguration, market: string): VisualizationConfiguration {
    return configuration.visualizationConfigurationList
      .find((config: VisualizationConfiguration) => {
        // TODO: unfortunately we need to rely on a naming convention for now...
        return (config.market === market && config.dashboard === "Overview"
                    && config.graphConfiguration.name.toLowerCase().indexOf("portfolio") > 1);
      });
  }

}
