import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GraphManagerPlotData } from "../../model/rest/graph-manager-plot-data";
import { PlotData, Series } from "../../model/rest/plot-data";

const financialPosition = "Financial";
const averagedAggregation = "Averaged";
const summedAggregation = "Summed";
const oneHour = 3600 * 1000;

interface TimeRange {
  start: number;
  end: number;
}

@Injectable()
export class GraphManagerService {

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  getGraph(graphId: string, timeInterval: string, intervalMultiplier: number, versionDate: Date): Observable<PlotData> {
    const url = this.bfcConfigurationService.configuration.myApiUrl + "services/graphs/" + graphId;
    const params = {
      timeInterval: undefined,
      intervalMultiplier: undefined,
      versionDate: undefined,
    };
    params.timeInterval = timeInterval;
    params.intervalMultiplier = intervalMultiplier.toString();
    if (versionDate !== null) {
      params.versionDate = versionDate.toISOString();
    } else {
      const today = new Date(); // default 'adhoc' date is current date according to GraphManager developer
      today.setHours(0, 0, 0, 0);
      params.versionDate = today.toISOString();
    }
    return this.httpClient.get(url, { params: params }).pipe(
      map((graphManagerPlotData: GraphManagerPlotData) => {
        GraphManagerService.removeNullValues(graphManagerPlotData);
        graphManagerPlotData.series.forEach(series => {
          if (series.positionType === financialPosition && series.xValueAggregation === averagedAggregation) {
            // eslint-disable-next-line max-len
            if (series.pointInterval && series.pointInterval <= oneHour) { // only use Approximation in case the interval is <= 1 h.
              // TAN-229 use custom approximation function for time series of type "Financial"
              // and aggregation "Averaged"
              // For an Explanation on how the calculation is done see https://jira.bkw.ch/jira/browse/TAN-229
              GraphManagerService.setGroupingApproximationFunction(series, graphManagerPlotData);
            } else {
              // if interval is >= 1h no approximation must be done for time series of type "Financial"
              // and aggregation "Averaged"
              series.disableApproximation = true;
            }
          } else {
            if (series.pointInterval && series.pointInterval <= oneHour) {
              switch (series.xValueAggregation) {
                case summedAggregation:
                  series.dataGroupingApproximation = "sum";
                  break;
                case averagedAggregation:
                  series.dataGroupingApproximation = "average";
                  break;
              }
            } else {
              series.disableApproximation = true;
            }
          }
        });
        if (graphManagerPlotData.series.some(series => series.stacked)){
          graphManagerPlotData.series.reverse();
        }
        return graphManagerPlotData;
      }),
    );
  }

  public static popDataGroupApproximation(pd: PlotData) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
    let dataGroupApproximations = [];
    for (let i = 0; i < pd.series.length; i++) {
      dataGroupApproximations[i] = pd.series[i].dataGroupingApproximation;
      pd.series[i].dataGroupingApproximation = undefined;
    }
    return dataGroupApproximations;
  }

  public static pushDataGroupApproximation(pd: PlotData, popped: any[]) {
    for (let i = 0; i < pd.series.length; i++) {
      pd.series[i].dataGroupingApproximation = popped[i];
    }
  }

  private static setGroupingApproximationFunction(series, graph: GraphManagerPlotData) {
    // eslint-disable-next-line max-len
    const nameSelector = series.name.indexOf("Peak") > -1 ? "Peak" : "Offpeak"; // unfortunately we must rely a on naming convention
    const financialSeries = graph.series.find(s => {
      return (
        s.positionType === financialPosition &&
        s.xValueAggregation === summedAggregation &&
        s.name.indexOf(nameSelector) > 0
      );
    });
    const priceSeries = graph.series.find(s => {
      return (s.timeSeriesType === "Price" && s.xValueAggregation === summedAggregation && s.name.indexOf(nameSelector) > 0);
    });
    if (financialSeries && priceSeries) {
      series.dataGroupingApproximation = function (dataGroupValues: number[]) {
        if (dataGroupValues.length == 0) {
          return undefined;
        }
        const timeRange = GraphManagerService.determineDataGroupTimeRange(series, this.dataGroupInfo, dataGroupValues);
        const financialSum = GraphManagerService.sumOverTimeRange(financialSeries, timeRange);
        const priceSum = GraphManagerService.sumOverTimeRange(priceSeries, timeRange);

        if (priceSum !== 0) {
          return financialSum / priceSum;
        } else {
          return 0;
        }
      };
    }
  }

  private static determineDataGroupTimeRange(series: Series, dataGroupInfo, dataGroupValues: number[]): TimeRange {
    let start = series.data[dataGroupInfo.start][0];
    let endIndex = dataGroupInfo.start + dataGroupInfo.length;
    let afterEnd = series.data.length > (endIndex) ? series.data[endIndex][0] : -1;
    const firstDataPoint = series.data[dataGroupInfo.start][1];
    const firstArrayValue = dataGroupValues[0];

    if (firstArrayValue !== firstDataPoint) {
      /* HighCharts offers two ways to aggregate data. It passes in an array with all values of a group and provides
             * context information with the index of the first data group element.
             *
             * When the graph is zoomed so that the first element of the first dataGroup != the first element in the
             * data array then the dataGroupInfo object may contain wrong indexes which are shifted by one group length.
             * This is likely a highcharts bug which is worked around here
             * (related: https://github.com/highcharts/highcharts/issues/7051).
             */
      // console.warn(`
      //     expected ${firstDataPoint} (value according to the datagroupindex) to be ${firstArrayValue}
      //     (first value of array passed into the aggregation function), will add an offset so that the first point
      //     is ${series.data[dataGroupInfo.start + dataGroupInfo.length]}`);
      start = series.data[dataGroupInfo.start + dataGroupInfo.length][0];
      afterEnd = start + 24 * 60 * 60 * 1000;
    }
    return { start: start, end: afterEnd };
  }

  private static sumOverTimeRange(series: Series, timeRange: TimeRange) {
    let sum = 0;
    series.data.filter(d => {
      return d[0] >= timeRange.start && (timeRange.end === -1 || d[0] < timeRange.end);
    }).forEach(d => {
      sum += d[1] ? d[1] : 0;
    });
    return sum;
  }

  private static removeNullValues(graphManagerPlotData: GraphManagerPlotData) {
    graphManagerPlotData.series.forEach(series => {
      // we can rely here on the fact that s.data will always be sorted
      // we need to save earliest and latest date because we then filter null values on the next lines
      // and this caused TAN-380
      if (series.data && series.data[0]) {
        series.earliestDate = series.data[0][0];
        series.latestDate = series.data[series.data.length - 1][0];
      }
      // highchart's data datdataGroupingApproximation does not work properly if there are null values!
      series.data = series.data.filter(d => {
        return d[1] !== null;
      });
    });
  }
}
