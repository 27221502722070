import { Injectable } from "@angular/core";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class InitializerProvider {

  constructor(private bfcTranslationService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcAuthenticationService: BfcAuthenticationService) {

  }

  public initialize() {
    this.bfcGoogleAnalyticsService
      .initGoogleTagManager(this.bfcConfigurationService.configuration.googleTagManagerContainerId);

    this.bfcAuthenticationService.login();

    const footerElements = document.getElementsByTagName("bfe-footer");
    if (footerElements.length === 1) {
      footerElements[0].setAttribute("customer-segment", "B2B");
      footerElements[0].setAttribute("contact-url", JSON.stringify("contact"));
    } else {
      throw (new Error("too many or no bfe-footer element found"));
    }
  }
}
