export const languageOptionsEN = {
  downloadJPEG: "Save graph as JPEG",
  downloadPDF: "Save graph as PDF",
  downloadPNG: "Save graph as PNG",
  downloadSVG: "Save graph as SVG",
  months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  decimalPoint: ",",
  printChart: "print diagram",
  rangeSelectorFrom: "From",
  rangeSelectorTo: "To",
};
