import { GraphManagerService } from "../graph-manager/graph-manager.service";
import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { Observable, Subject } from "rxjs";
import { PlotData } from "../../model/rest/plot-data";
@Injectable()
export class DiffCalculationService {
  constructor(private bfcTranslationService: BfcTranslationService) {}

  /**
   * Creates the difference between two graphs.
   * @param p1
   * @param p2
   * @returns {PlotData}
   */
  createDiff(p1: PlotData, p2: PlotData): Observable<PlotData> {
    const deltaPlotSubject = new Subject<PlotData>();

    const worker = new Worker(new URL("./diff-calculation.worker", import.meta.url), { type: "module" });

    worker.onmessage = ({ data }) => {
      const deltaPlot: PlotData = data;
      deltaPlot.title = this.bfcTranslationService.translate("PORTFOLIO.GRAPH.DELTA_POS1_POS2");
      p1.series.forEach((s) => {
        // use same colors for delta plot as for plot number 1.
        deltaPlot.series[p1.series.indexOf(s)].color = s.color;
      });
      deltaPlotSubject.next(deltaPlot);
      deltaPlotSubject.complete();
    };

    worker.onerror = (error) => {
      deltaPlotSubject.error(error);
    };

    let popped1 = GraphManagerService.popDataGroupApproximation(p1);
    let popped2 = GraphManagerService.popDataGroupApproximation(p2);

    worker.postMessage([p1, p2]);

    GraphManagerService.pushDataGroupApproximation(p1, popped1);
    GraphManagerService.pushDataGroupApproximation(p2, popped2);

    return deltaPlotSubject;
  }
}
